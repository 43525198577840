
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Company: { default: () => { return undefined }, type: Object as PropType<Company> }
  },
  computed: {
    SliceDate: function () {
      if (!this.Company) return ''
      return new Date(this.Company.AddedDate.replaceAll(' ', '')).toUTCString().slice(4, 16)
    }

  },
  methods: {
    EditCompany (Company :Company) {
      if (!this.Access('HR_Departments_Read')) {
        return
      }
      this.OpenDialog('Company_Dialog', 'Edit Company (' + Company.Name + ')', { Data: Company, Editing: true }, {})
    },
    DeleteCompany (Company :Company) {
      if (!this.Access('Companies_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Companies', AddQuery: '/' + Company.ID, Text: 'Are you sure you want to delete <strong>' + Company.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    }
  }
})
