
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<AttendanceRequest>
    }
  },
  computed: {
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Request',
          this: this,
          Function: 'NewAttendanceRequest',
          Icon: 'Add',
          Visible: this.Access('HR_AttendanceRequests_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['EmployeeID'],
            Sort: ['EmployeeID']
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type'],
            Sort: ['Type']
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              Pending: 'BGYellow',
              Approved: 'BGGreen',
              Rejected: 'BGRed',
              Canceled: 'BGRed2'
            },
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Note',
            Path: ['Note'],
            Sort: ['Note']
          },
          {
            Type: 'Time',
            Name: 'Leave Hours',
            Path: ['LeaveHours'],
            Sort: ['LeaveHours'],
            Unit: this.Translate('Hour')
          },
          {
            Type: 'Normal',
            Name: 'Start Time',
            Path: ['StartTime'],
            Sort: ['StartTime']
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['Date'],
            Sort: ['Date']
          },
          {
            Type: 'DateTime',
            Name: 'Date To',
            Path: ['DateTo'],
            Sort: ['DateTo']
          },
          {
            Type: 'DateTime',
            Name: 'Request Date ',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_AttendanceRequests_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditAttendanceRequest',
                DynamicVisiblity: {
                  FixedValue: 'Pending',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Removed',
                Visible: this.Access('HR_AttendanceRequests_Edit'),
                ToolTip: this.Translate('Reject'),
                Emits: 'SwapStatusReject',
                DynamicVisiblity: {
                  FixedValue: 'Pending',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Done',
                Visible: this.Access('HR_AttendanceRequests_Edit'),
                ToolTip: this.Translate('Accepet'),
                Emits: 'SwapStatusAccepet',
                DynamicVisiblity: {
                  FixedValue: 'Pending',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    // NewAttendanceRequest () {
    //   if (!this.Access('HR_AttendanceRequests_Create')) {
    //     return
    //   }
    //   this.OpenDialog('HR_AttendanceRequest_Dialog', this.Translate('New Attendance Request'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    // },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_AttendanceRequest').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading AttendanceRequest'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditAttendanceRequest (AttendanceRequest :AttendanceRequest) {
      if (!this.Access('HR_AttendanceRequests_Read')) {
        return
      }
      this.OpenDialog('HR_AttendanceRequest_Dialog', 'Edit AttendanceRequest (' + AttendanceRequest.Type + ')', { Data: AttendanceRequest, Editing: true }, {})
    },
    DeleteAttendanceRequest (AttendanceRequest :AttendanceRequest) {
      if (!this.Access('HR_AttendanceRequests_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_AttendanceRequest', AddQuery: '/' + AttendanceRequest.ID, Text: 'Are you sure you want to delete <strong>' + AttendanceRequest.Type + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    SwapStatusAccepet (AttendanceRequest :AttendanceRequest) {
      if (!this.Access('HR_AttendanceRequests_Edit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Accepet Request'), { PostMethod: 'PATCH', PostAPI: 'HR_AttendanceRequest', PostData: { Status: 'Approved' }, AddQuery: '/' + AttendanceRequest.ID, Text: this.Translate('Are you sure you want to Accepet <strong>Request</strong>'), ButtonClass: 'BGGreen', ButtonText: this.Translate('Confirm'), Icon: 'Done' } as GeneralAcceptDialogData, {})
    },
    SwapStatusReject (AttendanceRequest :AttendanceRequest) {
      if (!this.Access('HR_AttendanceRequests_Edit')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Reject Request'), { PostMethod: 'PATCH', PostAPI: 'HR_AttendanceRequest', PostData: { Status: 'Rejected' }, AddQuery: '/' + AttendanceRequest.ID, Text: this.Translate('Are you sure you want to Reject <strong>Request</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm'), Icon: 'Removed' } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewAttendanceRequest':
          this.OpenDialog('HR_AttendanceRequest_Dialog', this.Translate('New Attendance Request'), {})
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
