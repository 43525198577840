
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      Departments: [] as Array<Department>,
      CookiesData: [] as Array<Employee>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Department',
            Visible: true,
            Options: this.Departments,
            Selectors: [['Name']],
            Res: 'HR_Filter_Department',
            DataPath: ['Department', 'ID'],
            ListPath: ['ID']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: this.Translate('Active'),
                Visible: this.Access('HR_Employees_Edit'),
                Icon: 'Done',
                Res: 'Users_Filter_Active',
                Value: 'Active',
                DataPath: ['Status']
              },
              {
                Name: this.Translate('Inactive'),
                Visible: this.Access('HR_Employees_Edit'),
                Icon: 'Inactive',
                Res: 'Users_Filter_Inactive',
                Value: 'Inactive',
                DataPath: ['Status']
              }
            ]
          }
        ]
      }
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Sort: ['ImgUrl'],
            Style: 'width: 2em',
            ImageStyle: 'border-radius: 100%; width: 3em; height: 3em;',
            ImageClass: 'Shadow1',
            ImageDefault: this.$store.state.CookiesConfig.UserImage
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['ID'],
            Sort: ['ID'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Phone',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber'],
            IsSearchable: true,
            IsSearchExclusive: true
          },
          {
            Type: 'Normal',
            Name: 'Email',
            Path: ['Email'],
            Sort: ['Email'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Department',
            Path: ['Department', 'Name'],
            Sort: ['Department', 'Name'],
            IsSearchable: true,
            Secondary: {
              Path: ['Position', 'Name']
            }
          },
          {
            Type: 'DateTime',
            Name: 'Hire Date',
            Path: 'HireDate',
            Sort: 'HireDate',
            IsSearchable: true
          },
          // {
          //   Type: 'Normal',
          //   Name: 'Salary',
          //   Path: ['Salary'],
          //   Sort: ['Salary'],
          //   Unit: this.PriceUnit,
          //   IsSearchable: true
          // },
          {
            Type: 'Normal',
            Name: 'Nationality',
            Path: ['Nationality', 'Name'],
            Sort: ['Nationality', 'Name'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Gender',
            Path: ['Gender'],
            Sort: ['Gender'],
            IsSearchable: true
          },
          {
            Type: 'DateTime',
            Name: 'Birth Date',
            Path: 'BirthDate',
            Sort: 'BirthDate',
            IsSearchable: true
          },
          {
            Type: 'QR',
            Name: 'QRCode',
            Path: 'Name',
            Sort: 'Name'
          },
          {
            Type: 'StatusBox',
            Name: 'Status',
            Path: 'Status',
            Sort: 'Status',
            StatusClassColors: {
              Active: 'BGGreen',
              Inactive: 'BGRed'
            },
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Inactive',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Activate'),
                Style: 'filter: saturate(0); opacity: 0.5;',
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Inactive',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Inactive',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Deactivate'),
                Emits: 'SwapStatus',
                DynamicVisiblity: {
                  FixedValue: 'Active',
                  Operator: 'EQUAL LOWERCASE',
                  ValuePath: 'Status'
                }
              },
              {
                ID: 'Edit',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditEmployee'
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Employees_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: 'DeleteEmployee'
              },
              // {
              //   ID: 'Printer',
              //   ToolTip: 'Print',
              //   Emits: 'PrintSalarySlip',
              //   Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              // },
              {
                ID: 'Employees',
                Visible: this.Access('HR_Employees_Read'),
                ToolTip: this.Translate('View Employee'),
                Emits: 'ViewEmployee',
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Phone',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Assign New Device'),
                Emits: 'AssignNewDevice',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: null,
                  ValuePath: ['Devices']
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              },
              {
                ID: 'Phone',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Assign Device'),
                Emits: 'AssignDevice',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: null,
                  ValuePath: ['Devices']
                },
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1); opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Employee',
          this: this,
          Function: 'NewEmployee',
          Icon: 'Add',
          Visible: this.Access('HR_Employees_Create')
        },
        // {
        //   Name: 'Archived Employees',
        //   this: this,
        //   Function: 'ArchivedEmployees',
        //   Icon: 'Employees',
        //   Visible: this.Access('HR_Employees_Read')
        // },
        {
          Name: 'Daily Employees',
          this: this,
          Function: 'DailyEmployees',
          Icon: 'Employees',
          Visible: this.Access('HR_Employees_Read')
        }
      ]
    }
  },
  methods: {
    ReadDepartments () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Departments').then(response => {
        this.Departments = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Departments'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewEmployee () {
      if (this.Access('HR_Employees_Create')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', this.Translate('New Employee'), {}, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.ReadDepartments()
      this.Get('HR_Employees', '?IsDaily=0').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditEmployee (Employee :Employee) {
      if (!this.Access('HR_Employees_Read')) {
        return
      }
      this.OpenDialog('HR_Employee_Dialog', 'Edit Employee (' + Employee.Name + ')', { Data: Employee, Editing: true }, {})
    },
    DeleteEmployee (Employee :Employee) {
      if (!this.Access('HR_Employees_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Employees', AddQuery: '/' + Employee.ID, Text: 'Are you sure you want to delete <strong>' + Employee.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.EmployeeDeleted, AfterDialogFunctionValue: Employee.ImgUrl } as GeneralAcceptDialogData, {})
    },
    EmployeeDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewEmployee':
          this.OpenDialog('HR_Employee_Dialog', this.Translate('New Employee'), {}, { NoBackgroundClose: true })
          break
        case 'ArchivedEmployees':
          this.$router.push('/HR/ArchivedEmployees')
          break
        case 'DailyEmployees':
          this.$router.push('/HR/DailyEmployees')
          break
      }
    },
    SwapStatus (Employee :Employee) {
      if (!this.Access('HR_Employees_Remove')) {
        return
      }
      var CurrentStatus = Employee.Status
      if (CurrentStatus === 'Active') {
        this.OpenDialog('General_Accept_Dialog', this.Translate('Deactivate Employee'), { PostMethod: 'PATCH', PostAPI: 'HR_Employees', PostData: { Status: 'Inactive' }, AddQuery: '/' + Employee.ID, Text: this.Translate('Are you sure you want to Deactivate <strong>' + Employee.Name + '</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm'), Icon: 'Inactive' } as GeneralAcceptDialogData, {})
      } else {
        this.OpenDialog('General_Accept_Dialog', this.Translate('Activate Employee'), { PostMethod: 'PATCH', PostAPI: 'HR_Employees', PostData: { Status: 'Active' }, AddQuery: '/' + Employee.ID, Text: this.Translate('Are you sure you want to Activate <strong>' + Employee.Name + '</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm') } as GeneralAcceptDialogData, {})
      }
    },
    PrintSalarySlip (Transfer :InventoryTransfer) {
      this.OpenDialog('HR_PrintEmployeeSalarySlip_Dialog', 'Print Salary Slip', { Data: { ...Transfer, Type: 'InternalTransfer' } }, { NoBackgroundClose: true, FullWindow: true, background: 'none', header: false })
    },
    ViewEmployee (Employee :Employee) {
      this.$router.push('/HR/Employee/' + Employee.ID)
    },
    AssignNewDevice (Employee :Employee) {
      if (!this.Access('HR_Employees_Edit')) {
        return
      }
      this.OpenDialog('HR_EmployeeDevice_Dialog', this.Translate(`${Employee.Name} Device`), { Data: Employee }, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    AssignDevice (Employee :Employee) {
      if (!this.Access('HR_Employees_Edit')) {
        return
      }
      this.OpenDialog('HR_EmployeeDevice_Dialog', this.Translate(`${Employee.Name} Device`), { Data: Employee, Editing: true }, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
