
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ShowFilter: false,
      Search: ''
    }
  },
  computed: {
    // Filters () :any {
    //   var EmployeesFilter :FilterObject = {
    //     Lists: [
    //       {
    //         Name: 'ssss',
    //         Visible: true,
    //         Options: [{ name: 'khaled' }, { name: 'mohammed' }],
    //         Selectors: ['name'],
    //         Res: 'Manufacturersc',
    //         DataPath: ['Notes'],
    //         ListPath: ['name']
    //       }
    //     ],
    //     Switches: [
    //       {
    //         Name: this.Translate('Manufacturer = 2'),
    //         Visible: true,
    //         Icon: 'Factory',
    //         Res: 'FinanceFilter_Manufacturers2',
    //         Value: 2,
    //         DataPath: ['ManufacturerID']
    //       }
    //     ]
    //   }
    //   return {
    //     Employeess: EmployeesFilter
    //   }
    // },
    Tabs () {
      return [
        { Name: this.Translate('Employees'), Link: 'Employees' },
        { Name: this.Translate('Departments'), Link: '/HR/Departments' },
        { Name: this.Translate('Positions'), Link: '/HR/Positions' },
        { Name: this.Translate('Warnings'), Link: '/HR/Warnings' },
        { Name: this.Translate('Deductions'), Link: '/HR/Deductions' },
        { Name: this.Translate('Attendance'), Link: '/HR/Attendance' },
        { Name: this.Translate('Requests'), Link: '/HR/AttendanceRequest' },
        { Name: this.Translate('Settings'), Link: '/HR/HRSettings' }
      ] as Array<CookiesTabs>
    },
    Name () {
      return this.Translate('HR')
    }
  }
})
