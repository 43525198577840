import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5575d8e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    onDragenter: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Dropping = true)),
    onDragleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Dropping = false)),
    onDragover: _cache[5] || (_cache[5] = ($event: any) => (_ctx.PreventDragEvent($event))),
    onDrop: _cache[6] || (_cache[6] = ($event: any) => (_ctx.FileDropped($event))),
    class: _normalizeClass([ [_ctx.ErrorData && ' ErrorBorder ', (_ctx.Disabled || _ctx.Selecting) && ' Disabled ', _ctx.Dropping ? 'Dropping' : ''], "UserImage M-Auto"]),
    style: _normalizeStyle('background-image: url(' + _ctx.DefaultImageHandler(typeof(_ctx.MEMRead(_ctx.Res)) === 'string' ? _ctx.MEMRead(_ctx.Res) : _ctx.SelectedImgUrl, _ctx.Placeholder) + ');')
  }, [
    _createElementVNode("input", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Clicked())),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectionDone())),
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.UserImageChanged())),
      name: _ctx.Res,
      type: "file",
      accept: "image/*"
    }, null, 40, _hoisted_1)
  ], 38))
}