
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_CustomLocationsExport_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'ItemCode',
            Bold: true
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize'
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'DecodedLoc',
            Bold: true
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 0,
            numberInput_Min: 0,
            Value: ['NOTHING'],
            Res_ID: 'ID',
            numberInput_Max: ['Max']
          }
          // {
          //   Name: 'Total',
          //   Type: 'normal',
          //   Value: 'Total',
          //   Bold: true,
          //   Total: true
          // }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath === 'Not Enough Space'
        })
        console.log(Found)
        if (Found) {
          NoSpace = true
        }
      })
      return NoSpace
    },
    DecodedAvailable () {
      if (!this.DialogData || !this.DialogData.Data || this.DialogData.Data.length === 0) return []
      return this.DialogData?.Data.map((available :any) => {
        return {
          Max: available.PackSize * available.Quantity,
          // Total: available.PackSize * this.MEMRead('D_CustomLocationsExport_Table_Edited_Quantity_' + available.ID),
          ...available
        }
      })
    },
    Total () {
      var Total = 0
      if (!this.DialogData || !this.DialogData.Data) return Total
      this.DialogData.Data.forEach((avail:any) => {
        Total += this.MEMRead('D_CustomLocationsExport_Table_Edited_Quantity_' + avail.ID)
      })
      return Total
    }
  },
  // ---------------------------------------------------
  methods: {
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      if (!bodd) return
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    },
    SaveData () {
      var Locations :Array<any> = []
      this.DecodedAvailable.forEach((avail :any) => {
        if (this.MEMRead('D_CustomLocationsExport_Table_Edited_Quantity_' + avail.ID) > 0) {
          Locations.push({
            Path: avail.DecodedLoc,
            AvailID: avail.ID,
            Quantity: this.MEMRead('D_CustomLocationsExport_Table_Edited_Quantity_' + avail.ID)
          })
        }
      })
      var ParentThis = this.DialogData?.ParentThis
      if (ParentThis && ParentThis.TransferItems) {
        var Found = ParentThis.TransferItems.find((item:any) => {
          return item.ItemID === this.DialogData?.ItemID
        })
        if (Found) {
          Found.Location = Locations
          this.$router.go(-1)
        }
        // console.log('Locations')
        // console.log(Item)
        // console.log(Locations)
        // Item.Location = Locations
      }
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
