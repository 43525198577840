
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Position>
    }
  },
  computed: {
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Position',
          this: this,
          Function: 'NewPosition',
          Icon: 'Add',
          Visible: this.Access('HR_Positions_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Department Name',
            Path: ['Department', 'Name'],
            Sort: ['Department', 'Name'],
            Bold: true
          },
          // {
          //   Type: 'Normal',
          //   Name: 'Default Salary',
          //   Path: ['PayrollItem', 'Salary'],
          //   Sort: ['PayrollItem', 'Salary'],
          //   Bold: true
          // },
          {
            Type: 'Normal',
            Name: 'Employees',
            Path: ['EmployeeCount'],
            Sort: ['EmployeeCount']
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_Positions_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditPosition'
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Positions_Remove'),
                ToolTip: this.Translate('Delete'),
                Emits: 'DeletePosition',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: 0,
                  ValuePath: ['AllBranchesEmployeesCount']
                }
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Positions_Remove'),
                ToolTip: this.Translate('Can`t Delete'),
                Emits: '',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: 0,
                  ValuePath: ['AllBranchesEmployeesCount']
                },
                Style: 'opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    NewPosition () {
      if (!this.Access('HR_Positions_Create')) {
        return
      }
      this.OpenDialog('HR_Position_Dialog', this.Translate('New Position'), {}, { NoBackgroundClose: true }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Positions').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Positions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditPosition (Position :Position) {
      if (!this.Access('HR_Positions_Read')) {
        return
      }
      this.OpenDialog('HR_Position_Dialog', 'Edit Position (' + Position.Name + ')', { Data: Position, Editing: true }, {})
    },
    DeletePosition (Position :Position) {
      if (!this.Access('HR_Positions_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Positions', AddQuery: '/' + Position.ID, Text: 'Are you sure you want to delete <strong>' + Position.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewPosition':
          this.OpenDialog('HR_Position_Dialog', this.Translate('New Position'), {}, { NoBackgroundClose: true })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
