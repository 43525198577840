import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5377946f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "SizeHolder" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  ref: "prespectiveWindow",
  style: {"width":"100%","height":"100%"},
  viewBox: "0 0 100 100",
  preserveAspectRatio: "none"
}
const _hoisted_4 = ["points"]
const _hoisted_5 = ["x1", "y1", "x2", "y2"]
const _hoisted_6 = ["x1", "y1", "x2", "y2"]
const _hoisted_7 = ["x1", "y1", "x2", "y2"]
const _hoisted_8 = ["x1", "y1", "x2", "y2"]
const _hoisted_9 = ["x1", "y1", "x2", "y2"]
const _hoisted_10 = ["x1", "y1", "x2", "y2"]
const _hoisted_11 = { style: {"width":"100%","height":"100%","position":"absolute","inset":"0","pointer-events":"none"} }
const _hoisted_12 = { style: {"width":"100%","height":"100%","position":"absolute","inset":"0","pointer-events":"none"} }
const _hoisted_13 = { style: {"width":"100%","height":"100%","position":"absolute","inset":"0","pointer-events":"none"} }
const _hoisted_14 = { style: {"width":"100%","height":"100%","position":"absolute","inset":"0","pointer-events":"none"} }
const _hoisted_15 = { class: "M-Auto CropButton Pointer color2 F-Bold" }
const _hoisted_16 = {
  key: 0,
  class: "W-100 H-100 D-Flex",
  style: {"position":"fixed","background":"rgba(0,0,0,0.3)"}
}
const _hoisted_17 = { class: "M-Auto D-Flex" }
const _hoisted_18 = { class: "M-Auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!

  return (_openBlock(), _createElementBlock("div", {
    onTouchmove: _cache[21] || (_cache[21] = ($event: any) => (_ctx.IsTouching($event))),
    onMousemove: _cache[22] || (_cache[22] = ($event: any) => (_ctx.IsMoving($event))),
    onTouchend: _cache[23] || (_cache[23] = ($event: any) => (_ctx.StopGrapping())),
    onMouseup: _cache[24] || (_cache[24] = ($event: any) => (_ctx.StopGrapping())),
    class: "CookiesCropper",
    style: {"user-select":"none"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: "BGShadow",
          style: _normalizeStyle('clip-path: polygon('+ _ctx.ClipPath + ', 0% 0%, 0% 100%, 100% 100%, 100% 0%, 0% 0%' +');')
        }, null, 4),
        _createElementVNode("img", {
          src: _ctx.ImgURL,
          alt: ""
        }, null, 8, _hoisted_2),
        _createElementVNode("div", null, [
          (_openBlock(), _createElementBlock("svg", _hoisted_3, [
            _createElementVNode("polygon", {
              onTouchstart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.MultiPointTouched(['1', '2', '3', '4']))),
              onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.MultiPointGrapping(['1', '2', '3', '4']))),
              "vector-effect": "non-scaling-stroke",
              style: _normalizeStyle(['fill: rgba(0,0,0,0); stroke:' + _ctx.Theme.UserColors.main_75 + ';', {"cursor":"grab","stroke-width":"4","filter":"drop-shadow(0px 0px 5px rgba(104, 245, 255, 0.292))","stroke-dasharray":"7"}]),
              ref: "prespectiveCroper",
              id: "prespectiveCroper",
              points: _ctx.PolygonPoints
            }, null, 44, _hoisted_4),
            _createElementVNode("line", {
              "vector-effect": "non-scaling-stroke",
              class: "O-25",
              x1: _ctx.CropperLines.LineA.x1,
              y1: _ctx.CropperLines.LineA.y1,
              x2: _ctx.CropperLines.LineA.x2,
              y2: _ctx.CropperLines.LineA.y2,
              style: _normalizeStyle('stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:4;')
            }, null, 12, _hoisted_5),
            _createElementVNode("line", {
              "vector-effect": "non-scaling-stroke",
              class: "O-25",
              x1: _ctx.CropperLines.LineB.x1,
              y1: _ctx.CropperLines.LineB.y1,
              x2: _ctx.CropperLines.LineB.x2,
              y2: _ctx.CropperLines.LineB.y2,
              style: _normalizeStyle('stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:4;')
            }, null, 12, _hoisted_6),
            _createElementVNode("line", {
              onTouchstart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.MultiPointTouched(['1', '2']))),
              onMousedown: _cache[3] || (_cache[3] = ($event: any) => (_ctx.MultiPointGrapping(['1', '2']))),
              "vector-effect": "non-scaling-stroke",
              class: "O-0",
              style: _normalizeStyle([{"cursor":"n-resize"}, 'stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:16;']),
              x1: _ctx.CropperLines.Line12.x1,
              y1: _ctx.CropperLines.Line12.y1,
              x2: _ctx.CropperLines.Line12.x2,
              y2: _ctx.CropperLines.Line12.y2
            }, null, 44, _hoisted_7),
            _createElementVNode("line", {
              onTouchstart: _cache[4] || (_cache[4] = ($event: any) => (_ctx.MultiPointTouched(['2', '3']))),
              onMousedown: _cache[5] || (_cache[5] = ($event: any) => (_ctx.MultiPointGrapping(['2', '3']))),
              "vector-effect": "non-scaling-stroke",
              class: "O-0",
              style: _normalizeStyle([{"cursor":"e-resize"}, 'stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:16;']),
              x1: _ctx.CropperLines.Line23.x1,
              y1: _ctx.CropperLines.Line23.y1,
              x2: _ctx.CropperLines.Line23.x2,
              y2: _ctx.CropperLines.Line23.y2
            }, null, 44, _hoisted_8),
            _createElementVNode("line", {
              onTouchstart: _cache[6] || (_cache[6] = ($event: any) => (_ctx.MultiPointTouched(['3', '4']))),
              onMousedown: _cache[7] || (_cache[7] = ($event: any) => (_ctx.MultiPointGrapping(['3', '4']))),
              "vector-effect": "non-scaling-stroke",
              class: "O-0",
              style: _normalizeStyle([{"cursor":"n-resize"}, 'stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:16;']),
              x1: _ctx.CropperLines.Line34.x1,
              y1: _ctx.CropperLines.Line34.y1,
              x2: _ctx.CropperLines.Line34.x2,
              y2: _ctx.CropperLines.Line34.y2
            }, null, 44, _hoisted_9),
            _createElementVNode("line", {
              onTouchstart: _cache[8] || (_cache[8] = ($event: any) => (_ctx.MultiPointTouched(['4', '1']))),
              onMousedown: _cache[9] || (_cache[9] = ($event: any) => (_ctx.MultiPointGrapping(['4', '1']))),
              "vector-effect": "non-scaling-stroke",
              class: "O-0",
              style: _normalizeStyle([{"cursor":"e-resize"}, 'stroke:' + _ctx.Theme.UserColors.third_75 + '; stroke-width:16;']),
              x1: _ctx.CropperLines.Line41.x1,
              y1: _ctx.CropperLines.Line41.y1,
              x2: _ctx.CropperLines.Line41.x2,
              y2: _ctx.CropperLines.Line41.y2
            }, null, 44, _hoisted_10)
          ], 512)),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              ref: "PrespectiveCropPoint1",
              id: "PrespectiveCropPoint1",
              onTouchstart: _cache[10] || (_cache[10] = ($event: any) => (_ctx.pointgrapped('1'))),
              onMousedown: _cache[11] || (_cache[11] = ($event: any) => (_ctx.pointgrapped('1'))),
              style: _normalizeStyle('left: ' + _ctx.Cropper.P1.x + '%; top: ' + _ctx.Cropper.P1.y + '%;'),
              class: "CropperHandlers"
            }, null, 36)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              ref: "PrespectiveCropPoint2",
              id: "PrespectiveCropPoint2",
              onTouchstart: _cache[12] || (_cache[12] = ($event: any) => (_ctx.pointgrapped('2'))),
              onMousedown: _cache[13] || (_cache[13] = ($event: any) => (_ctx.pointgrapped('2'))),
              style: _normalizeStyle('left: ' + _ctx.Cropper.P2.x + '%; top: ' + _ctx.Cropper.P2.y + '%;'),
              class: "CropperHandlers"
            }, null, 36)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", {
              ref: "PrespectiveCropPoint3",
              id: "PrespectiveCropPoint3",
              onTouchstart: _cache[14] || (_cache[14] = ($event: any) => (_ctx.pointgrapped('3'))),
              onMousedown: _cache[15] || (_cache[15] = ($event: any) => (_ctx.pointgrapped('3'))),
              style: _normalizeStyle('left: ' + _ctx.Cropper.P3.x + '%; top: ' + _ctx.Cropper.P3.y + '%;'),
              class: "CropperHandlers"
            }, null, 36)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", {
              ref: "PrespectiveCropPoint4",
              id: "PrespectiveCropPoint4",
              onTouchstart: _cache[16] || (_cache[16] = ($event: any) => (_ctx.pointgrapped('4'))),
              onMousedown: _cache[17] || (_cache[17] = ($event: any) => (_ctx.pointgrapped('4'))),
              style: _normalizeStyle('left: ' + _ctx.Cropper.P4.x + '%; top: ' + _ctx.Cropper.P4.y + '%;'),
              class: "CropperHandlers"
            }, null, 36)
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", {
            class: "BG2",
            onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.CropIt && _ctx.CropIt(...args)))
          }, _toDisplayString(_ctx.Translate('Crop')), 1),
          _createElementVNode("div", {
            class: "BG2",
            onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.SkipCropping && _ctx.SkipCropping(...args)))
          }, _toDisplayString(_ctx.Translate('Skip')), 1),
          _createElementVNode("div", {
            class: "BGRed",
            onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.Cancel && _ctx.Cancel(...args)))
          }, _toDisplayString(_ctx.Translate('Cancel')), 1)
        ])
      ]),
      (_ctx.Saving)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_CookiesLoading, { class: "M-Auto" })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 32))
}