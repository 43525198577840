
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, undefined)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {}
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Profile',
          this: this,
          Function: 'NewProfile',
          Icon: 'Add',
          Visible: this.Access('Security_Profiles_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Path: 'UPDTime', Direction: -1 },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description'],
            Sort: ['Description'],
            MultiLine: true,
            Bold: true
          },
          {
            Type: 'DateTime',
            Name: 'Last Updated',
            DateStyle: 'DD/MM/YYYY - Time',
            Path: ['UPDTime'],
            Sort: ['UPDTime'],
            Bold: true
          },
          {
            Type: 'ActionIcons',
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: this.$store.state.UserManagment.CurrentUser.UserInfo.Profile,
              Operator: 'EQUAL',
              ValuePath: 'ID'
            },
            ActionIcons: [
              {
                ID: 'Key',
                Visible: this.Access('Security_Roles_Read'),
                ToolTip: 'Roles',
                Emits: 'EditProfile'
              },
              {
                ID: 'Clone',
                Visible: this.Access('Security_Profiles_Create'),
                ToolTip: 'Clone',
                Emits: 'CloneProfile'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteProfile',
                Visible: this.Access('Security_Profiles_Remove'),
                ToolTip: 'Remove'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Security_Profiles').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditProfile (Profile :Profile) {
      if (!this.Access('Security_Profiles_Read')) {
        return
      }
      this.OpenDialog('Security_Profile_Dialog', this.Translate('Edit Profile') + ' (' + Profile.Name + ')', { Data: Profile, Editing: true }, {})
    },
    CloneProfile (Profile :Profile) {
      if (!this.Access('Security_Profiles_Create')) {
        return
      }
      this.OpenDialog('Security_Profile_Dialog', 'Create New Profile', { Data: { ClonedID: Profile.ID } }, {})
    },
    DeleteProfile (Profile :Profile) {
      if (!this.Access('Security_Profiles_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Security_Profiles', AddQuery: '/' + Profile.ID, Text: 'Are you sure you want to delete <strong>' + Profile.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewProfile':
          this.OpenDialog('Security_Profile_Dialog', this.Translate('New Profile'), {})
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
