
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Items: { type: Object as PropType<NavItem[]> },
    IconsSheet: { default: '' }
  },
  methods: {
    LogOut () {
      this.OpenDialog('Security_Logout_Dialog', this.Translate('Log Out'), {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ChangeBranch () {
      this.OpenDialog('Inventory_Branch_Selector', 'Select Branch', {})
    },
    ChangeCompany () {
      this.OpenDialog('Inventory_Company_Selector', 'Select Company', {})
    },
    ChangeWarehouse () {
      this.OpenDialog('Inventory_Warehouse_Selector', 'Select Branch', {})
    },
    LogIN () {
      this.$store.state.AutoSignIn = false
      this.$store.state.Accessable = false
    },
    ChangeLanguage () {
      this.OpenDialog('ChangeLanguage', this.Translate('Select Language'), {})
    }
  },
  computed: {
    CurrentPage () {
      return this.$route.matched[0]?.name
    },
    Icons () {
      return this.IconsSheet === '' ? require('@/assets/icons/ThemeIcons.svg') : this.IconsSheet
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d5513cd0": (_ctx.Theme.BG3),
  "69cbf744": (_ctx.Theme.BackBlur),
  "fdd34a38": (_ctx.Theme.UserColors.main_50),
  "8f8acc6a": (_ctx.Theme.UserColors.fourth_25),
  "228fee7c": (_ctx.Theme.Shade3),
  "d5513cd2": (_ctx.Theme.BG2),
  "965607c6": (_ctx.Theme.BG2Fixed),
  "d5513cca": (_ctx.Theme.BG6),
  "8f8acbba": (_ctx.Theme.UserColors.fourth_50)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__