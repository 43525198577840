
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    ParentThis: { type: Object as PropType<any> },
    Search: { type: String, default: '' }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<FinanceAccount>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        // Lists: [
        //   {
        //     Name: 'Department',
        //     Visible: true,
        //     Options: this.Departments,
        //     Selectors: [['Name']],
        //     Res: 'HR_Filter_Department',
        //     DataPath: ['Department', 'ID'],
        //     ListPath: ['ID']
        //   }
        // ],
        // Checks: [
        //   {
        //     GroupName: 'Status',
        //     Checks: [
        //       {
        //         Name: this.Translate('Active'),
        //         Visible: this.Access('HR_Employees_Edit'),
        //         Icon: 'Done',
        //         Res: 'Users_Filter_Active',
        //         Value: 'Active',
        //         DataPath: ['Status']
        //       },
        //       {
        //         Name: this.Translate('Inactive'),
        //         Visible: this.Access('HR_Employees_Edit'),
        //         Icon: 'Inactive',
        //         Res: 'Users_Filter_Inactive',
        //         Value: 'Inactive',
        //         DataPath: ['Status']
        //       }
        //     ]
        //   }
        // ]
      }
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type'],
            Sort: ['Type']
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description']
          },
          {
            Type: 'Normal',
            Name: 'InitialBalance',
            Path: ['InitialBalance'],
            Bold: true,
            Unit: this.PriceUnit
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('Finance_Accounts_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditAccount'
              },
              {
                ID: 'Delete',
                Visible: this.Access('Finance_Accounts_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: 'DeleteAccount'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Account',
          this: this,
          Function: 'NewAccount',
          Icon: 'Add',
          Visible: this.Access('Finance_Accounts_Create')
        }
      ]
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Accounts').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Accounts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewAccount () {
      if (!this.Access('Finance_Accounts_Create')) {
        return
      }
      this.OpenDialog('Finance_Account_Dialog', this.Translate('New Account'), {}, { header: false }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditAccount (Account :FinanceAccount) {
      if (!this.Access('Finance_Accounts_Read')) {
        return
      }
      this.OpenDialog('Finance_Account_Dialog', 'Edit Account (' + Account.Name + ')', { Data: Account, Editing: true }, {})
    },
    DeleteAccount (Account :FinanceAccount) {
      if (!this.Access('Finance_Accounts_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Finance_Accounts', AddQuery: '/' + Account.ID, Text: 'Are you sure you want to delete <strong>' + Account.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewAccount':
          this.OpenDialog('Finance_Account_Dialog', this.Translate('New Account'), {})
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
