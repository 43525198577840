
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Warning>
    }
  },
  computed: {
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Warning',
          this: this,
          Function: 'NewWarning',
          Icon: 'Add',
          Visible: this.Access('HR_Warnings_Create')
        },
        {
          Name: 'Warnings Types',
          this: this,
          Function: 'WarningType',
          Icon: 'Edit',
          Visible: this.Access('HR_WarningType_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type', 'Name'],
            Sort: ['Type', 'Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name']
          },
          {
            Type: 'Normal',
            Name: 'Reason',
            Path: ['Reason'],
            Sort: ['Reason']
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_Warnings_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditWarning'
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Warnings_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: 'DeleteWarning',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: null,
                  ValuePath: ['DeductionID']
                }
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Warnings_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: '',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: null,
                  ValuePath: ['DeductionID']
                },
                Style: 'opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    NewWarning () {
      if (!this.Access('HR_Warnings_Create')) {
        return
      }
      this.OpenDialog('HR_Warning_Dialog', this.Translate('New Warning'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    WarningType () {
      if (!this.Access('HR_WarningType_Create')) {
        return
      }
      this.OpenDialog('HR_WarningType_Dialog', this.Translate('Warnings Types'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading')
      this.Get('HR_Warnings').then(response => {
        this.CookiesData = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Warnings'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditWarning (Warning :Warning) {
      if (!this.Access('HR_Warnings_Read')) {
        return
      }
      this.OpenDialog('HR_Warning_Dialog', 'Edit Warning (' + Warning.Type + ')', { Data: Warning, Editing: true }, {})
    },
    DeleteWarning (Warning :Warning) {
      if (!this.Access('HR_Warnings_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Warnings', AddQuery: '/' + Warning.ID, Text: 'Are you sure you want to delete <strong>' + Warning.Type + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewWarning':
          this.OpenDialog('HR_Warning_Dialog', this.Translate('New Warning'), {})
          break
        case 'WarningType':
          this.OpenDialog('HR_WarningType_Dialog', this.Translate('Warnings Types'), { Data: {} })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
