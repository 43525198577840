
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, undefined)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Branch>,
      Profiles: [] as Array<Profile>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {}
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Branch',
          this: this,
          Function: 'NewBranch',
          Icon: 'Add',
          Visible: this.Access('Branches_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        DefaultSort: { Direction: -1, Path: 'Name' },
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Style: 'width: 2em',
            ImageStyle: 'width: 3em; height: 3em;',
            ImageClass: 'Shadow1',
            ImageDefault: this.$store.state.CookiesConfig.BranchImage
          },
          {
            Type: 'Normal',
            Name: 'Branch',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Type: 'Normal',
            Name: 'Address',
            Path: ['Address'],
            Sort: ['Address']
          },
          {
            Type: 'Normal',
            Name: 'Postal Code',
            Path: ['PostalCode'],
            Sort: ['PostalCode']
          },
          {
            Type: 'Normal',
            Name: 'Phone Number',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber']
          },
          {
            Type: 'DateTime',
            Name: 'Date Added',
            Path: 'AddedDate',
            Sort: 'AddedDate'
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Location',
                Visible: this.Access('Branches_View'),
                ToolTip: this.Translate('View Location'),
                Emits: 'ViewLocation'
              },
              {
                ID: 'Edit',
                Visible: this.Access('Branches_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditBranch'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteBranch',
                Visible: this.Access('Branches_Remove'),
                ToolTip: this.Translate('Remove')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Branches').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Branches'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditBranch (Branch :Branch) {
      if (!this.Access('Branches_Edit')) {
        return
      }
      this.OpenDialog('Branches_Managment_Dialog', this.Translate('Edit Branch') + ' (' + Branch.Name + ')', { Data: Branch, Editing: true }, {})
    },
    DeleteBranch (Branch :Branch) {
      if (!this.Access('Branches_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Branches', AddQuery: `/${Branch.ID}`, Text: 'Are you sure you want to delete <strong>' + Branch.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.BranchDeleted, AfterDialogFunctionValue: Branch.ImgUrl } as GeneralAcceptDialogData, {})
    },
    BranchDeleted (ImgUrl :string) {
      if (ImgUrl) this.DeleteImage(ImgUrl)
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewBranch':
          this.OpenDialog('Branches_Managment_Dialog', this.Translate('New Branch'), {})
          break
      }
    },
    ViewLocation (Branch :Branch) {
      window.open('https://www.google.com/maps?q=' + Branch.GPSLatitude + ',' + Branch.GPSLongitude)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
