
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      Departments: [] as Array<Department>,
      CookiesData: [] as Array<Employee>
    }
  },
  computed: {
    Filter () :FilterObject {
      return {
        Lists: [
          {
            Name: 'Department',
            Visible: true,
            Options: this.Departments,
            Selectors: [['Name']],
            Res: 'HR_Filter_Department',
            DataPath: ['Department', 'ID'],
            ListPath: ['ID']
          }
        ],
        Checks: [
          {
            GroupName: 'Status',
            Checks: [
              {
                Name: this.Translate('Active'),
                Visible: this.Access('HR_Employees_Edit'),
                Icon: 'Done',
                Res: 'Users_Filter_Active',
                Value: 'Active',
                DataPath: ['Status']
              },
              {
                Name: this.Translate('Inactive'),
                Visible: this.Access('HR_Employees_Edit'),
                Icon: 'Inactive',
                Res: 'Users_Filter_Inactive',
                Value: 'Inactive',
                DataPath: ['Status']
              }
            ]
          }
        ]
      }
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Image',
            Path: 'ImgUrl',
            Sort: ['ImgUrl'],
            Style: 'width: 2em',
            ImageStyle: 'border-radius: 100%; width: 3em; height: 3em;',
            ImageClass: 'Shadow1',
            ImageDefault: this.$store.state.CookiesConfig.UserImage
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['ID'],
            Sort: ['ID'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Phone',
            Path: ['PhoneNumber'],
            Sort: ['PhoneNumber'],
            IsSearchable: true,
            IsSearchExclusive: true
          },
          {
            Type: 'Normal',
            Name: 'Email',
            Path: ['Email'],
            Sort: ['Email'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Department',
            Path: ['Department', 'Name'],
            Sort: ['Department', 'Name'],
            IsSearchable: true,
            Secondary: {
              Path: ['Position', 'Name']
            }
          },
          {
            Type: 'DateTime',
            Name: 'Hire Date',
            Path: 'HireDate',
            Sort: 'HireDate',
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Salary',
            Path: ['Salary'],
            Sort: ['Salary'],
            Unit: this.PriceUnit,
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Nationality',
            Path: ['Nationality', 'Name'],
            Sort: ['Nationality', 'Name'],
            IsSearchable: true
          },
          {
            Type: 'Normal',
            Name: 'Gender',
            Path: ['Gender'],
            Sort: ['Gender'],
            IsSearchable: true
          },
          {
            Type: 'DateTime',
            Name: 'Birth Date',
            Path: 'BirthDate',
            Sort: 'BirthDate',
            IsSearchable: true
          },
          {
            Type: 'QR',
            Name: 'QRCode',
            Path: 'Name',
            Sort: 'Name'
          },
          // {
          //   Type: 'StatusBox',
          //   Name: 'Status',
          //   Path: 'Status',
          //   Sort: 'Status',
          //   StatusClassColors: {
          //     Active: 'BGGreen',
          //     Inactive: 'BGRed'
          //   },
          //   Bold: true
          // },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Hide',
                Visible: this.Access('HR_Employees_Edit'),
                ToolTip: this.Translate('Unarchived'),
                Emits: 'UnarchivedEmployee'
              },
              {
                ID: 'Employees',
                Visible: this.Access('HR_Employees_Read'),
                ToolTip: this.Translate('View Employee'),
                Emits: 'ViewEmployee',
                Style: 'filter: Saturate(0) Brightness(1.5) contrast(1);'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadDepartments () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Departments').then(response => {
        this.Departments = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Positions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.ReadDepartments()
      // this.Get('HR_Employees', '?DateTime_From=2023-11-17&DateTime_To=2023-11-19')
      this.Get('HR_Employees', '?IsDeleted=true').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    UnarchivedEmployee (Employee :Employee) {
      if (!this.Access('HR_Employees_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', this.Translate('Unarchived Employee'), { PostMethod: 'PATCH', PostAPI: 'HR_Employees', PostData: { IsDeleted: false }, AddQuery: '/' + Employee.ID, Text: this.Translate('Are you sure you want to Unarchived <strong>' + Employee.Name + '</strong>'), ButtonClass: 'BGRed', ButtonText: this.Translate('Confirm'), Icon: 'Show' } as GeneralAcceptDialogData, {})
    },
    ViewEmployee (Employee :Employee) {
      this.$router.push('/HR/Employee/' + Employee.ID)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function (newVal, oldVal) { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    }
  }
})
