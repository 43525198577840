<template>
<div class=" D-Flex M-Auto FlexWrap " :class="Disabled ? 'CookiesInputDisabled' : ''">
    <span v-if="Name !== ''" style="padding: 0.2em 0.5em;" class="M-Auto">{{Name}}</span>
    <div class="CookiesNumberInput" :class="ErrorData && 'ErrorBorderNumber'"  :style="InputNegative">
      <div v-if="Buttons" :style="$store.getters.Direction === 'rtl' && { order: 100 }" @click="Decrement" class="CookiesNumButton CookiesDarker"><span class=" M-Auto">-</span></div>
      <input :placeholder="PlaceHolder" :disabled="Disabled" :min="Min" :max="Max" @change="ChangeValue()" class="CookiesNumInput" style="direction: ltr !important;" type="number" name="CookiesInput" :value="DecimalFixer($store.state.CookiesMemory[Res] * (IsPercentage ? 100 : 1 ))" :style="InputSize">
      <label v-if="!(Unit === '')" class="CookiesInputLabel" :style="DirectionalPadding" for="CookiesInput">{{Unit}}</label>
      <div v-if="Buttons" :style="$store.getters.Direction === 'rtl' && { order: -1 }" @click="Increment" class="CookiesNumButton"><span class=" M-Auto">+</span></div>
    </div>
</div>
</template>

<script>
export default {
  name: 'CookiesNumberInput',
  created () {
    if (!this.PreserveMemory) {
      this.$store.state.CookiesMemory[this.Res] = undefined
    }
    if (this.Default !== null) {
      this.ResetValue(false)
    } else if (!this.$store.state.CookiesMemory[this.Res] && this.$store.state.CookiesMemory[this.Res] !== 0) {
      if (!this.$store.state.CookiesMemory[this.Res] < this.Min) {
        this.$store.state.CookiesMemory[this.Res] = this.Min
      } else if (!this.$store.state.CookiesMemory[this.Res] > this.Max) {
        this.$store.state.CookiesMemory[this.Res] = this.Max
      }
    }
  },
  data () {
    return {}
  },
  props: {
    Default: { default: '' },
    Unit: { default: '', Type: String },
    Res: { default: null, type: String },
    Min: { default: -99999999, Type: Number },
    Max: { default: 99999999, Type: Number },
    Step: { default: 1, Type: Number },
    Disabled: { default: false, type: Boolean },
    Decimals: { default: 6, Type: Number },
    FixedWidth: { default: '', Type: String },
    MinWidth: { default: '1.6em', Type: String },
    PlaceHolder: { default: '', Type: String },
    Name: { default: '', Type: String },
    ErrorData: { default: false },
    NoZero: { default: false, type: Boolean },
    PreserveMemory: { default: true, type: Boolean },
    DefaultReset: { default: false },
    Buttons: { default: true, type: Boolean },
    IsPercentage: { default: false, type: Boolean }
  },
  methods: {
    Increment () {
      if (!this.$store.state.CookiesMemory[this.Res]) {
        this.$store.state.CookiesMemory[this.Res] = 0
      }
      if (this.$store.state.CookiesMemory[this.Res] < this.Min) {
        this.$store.state.CookiesMemory[this.Res] = this.Min
      } else if (this.$store.state.CookiesMemory[this.Res] + this.Step >= this.Max) {
        this.$store.state.CookiesMemory[this.Res] = this.Max
      } else {
        this.$store.state.CookiesMemory[this.Res] += this.Step
      }
      if (this.NoZero & this.$store.state.CookiesMemory[this.Res] === 0) {
        if (this.Max <= 0) {
          this.$store.state.CookiesMemory[this.Res] -= this.Step
        } else if (this.Min >= 0) {
          this.$store.state.CookiesMemory[this.Res] += this.Step
        }
      }
      this.$store.state.CookiesMemory[this.Res] = this.Round(this.$store.state.CookiesMemory[this.Res])
      this.$emit('changed', this.$store.state.CookiesMemory[this.Res])
    },
    Decrement () {
      if (!this.$store.state.CookiesMemory[this.Res]) {
        this.$store.state.CookiesMemory[this.Res] = 0
      }
      if (this.$store.state.CookiesMemory[this.Res] - this.Step <= this.Min) {
        this.$store.state.CookiesMemory[this.Res] = this.Min
      } else if (this.$store.state.CookiesMemory[this.Res] > this.Max) {
        this.$store.state.CookiesMemory[this.Res] = this.Max
      } else {
        this.$store.state.CookiesMemory[this.Res] -= this.Step
      }
      if (this.NoZero & this.$store.state.CookiesMemory[this.Res] === 0) {
        if (this.Max <= 0) {
          this.$store.state.CookiesMemory[this.Res] -= this.Step
        } else if (this.Min >= 0) {
          this.$store.state.CookiesMemory[this.Res] += this.Step
        }
      }
      this.$store.state.CookiesMemory[this.Res] = this.Round(this.$store.state.CookiesMemory[this.Res])
      this.$emit('changed', this.$store.state.CookiesMemory[this.Res])
    },
    ChangeValue () {
      var EnteredValue = Number(event.target.value) / (this.IsPercentage ? 100 : 1)
      if (EnteredValue < this.Min) {
        this.$store.state.CookiesMemory[this.Res] = this.Min
        event.target.value = this.Min
      } else if (EnteredValue > this.Max) {
        this.$store.state.CookiesMemory[this.Res] = this.Max
        event.target.value = this.Max
      } else {
        this.$store.state.CookiesMemory[this.Res] = EnteredValue
        event.target.value = EnteredValue
      }
      if (this.NoZero & this.$store.state.CookiesMemory[this.Res] === 0) {
        if (this.Max <= 0) {
          this.$store.state.CookiesMemory[this.Res] -= this.Step
        } else if (this.Min >= 0) {
          this.$store.state.CookiesMemory[this.Res] += this.Step
        }
      }
      this.$store.state.CookiesMemory[this.Res] = this.Round(this.$store.state.CookiesMemory[this.Res])
      this.$emit('changed', this.$store.state.CookiesMemory[this.Res])
    },
    ResetValue (EmitChanges = true) {
      if (!this.DefaultReset & this.MEMRead(this.Res) !== undefined) {
        if (this.MEMRead(this.Res) > this.Max) {
          this.$store.state.CookiesMemory[this.Res] = this.Max
        } else if (this.MEMRead(this.Res) < this.Min) {
          this.$store.state.CookiesMemory[this.Res] = this.Min
        }
      } else {
        if (this.Default > this.Max) {
          this.$store.state.CookiesMemory[this.Res] = this.Max
        } else if (this.Default < this.Min) {
          this.$store.state.CookiesMemory[this.Res] = this.Min
        } else {
          this.$store.state.CookiesMemory[this.Res] = this.Default
        }
      }
      // handling no zero type
      if (this.NoZero & this.$store.state.CookiesMemory[this.Res] === 0) {
        if (this.Max <= 0) {
          this.$store.state.CookiesMemory[this.Res] -= this.Step
        } else if (this.Min >= 0) {
          this.$store.state.CookiesMemory[this.Res] += this.Step
        }
      }
      if (EmitChanges) this.$emit('changed', this.$store.state.CookiesMemory[this.Res])
    },
    Round (number) {
      return Math.round(number * Math.pow(10, this.Decimals)) / Math.pow(10, this.Decimals)
    }
  },
  computed: {
    InputSize () {
      var Value = this.DecimalFixer(this.$store.state.CookiesMemory[this.Res])
      var length = Value.toString().length * 0.6
      if (this.FixedWidth !== '') {
        return { width: this.FixedWidth, minWidth: '' }
      }
      return { width: length + 'em', minWidth: this.MinWidth }
    },
    InputNegative () {
      var Width = this.$store.state.CookiesMemory[this.Res]
      if (Width >= 0) {
        var NegativeFilter = ''
      } else {
        NegativeFilter = 'rgba(221, 31, 31, 0.43)'
      }
      return { background: NegativeFilter }
    },
    PathHandler () {
      const getNestedObject = (nestedObj, pathArr) => {
        return pathArr.reduce((obj, key) =>
          (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
      }
      alert(getNestedObject(this.$store.state.CookiesMemory, ['personalInfo', 'name']))
      return 1
    },
    DirectionalPadding () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'padding-right: 0.4em; margin-left: -0.4em'
      } else {
        return 'padding-right: -0.4em; margin-left: 0.4em'
      }
    }
  },
  watch: {
    Min: function () { // watch it
      try {
        this.ResetValue()
      } catch (error) {
        console.log(error)
      }
    },
    Max: function () { // watch it
      try {
        this.ResetValue()
      } catch (error) {
        console.log(error)
      }
    },
    Default: function (newVal, oldVal) { // watch it
      if (newVal === this.MEMRead(this.Res)) return
      try {
        this.ResetValue(false)
      } catch (error) {
        console.log(error)
      }
    },
    Res: function () { // watch it
      try {
        this.ResetValue()
      } catch (error) {
        console.log(error)
      }
    }
  },
  emits: {
    changed: ''
  }
}
</script>

<style scoped>
.CookiesInputLabel{
  margin: auto;
  white-space: nowrap;
}

*{
  transition-delay: 0s;
  transition-duration: 0.3s;
  transition-property: all;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  /* border-radius: var(--rad3); */
  border-radius: 0.35em;
  overflow: hidden;
  background: v-bind('Theme.Light2');
  box-shadow: var(--innershadow1);
}

.CookiesNumInput{
  display: flex;
  border: none;
  outline: none;
  font-size: 1.2em;
  padding: 0.1em 0.5em ;
  width: 40px;
  appearance: none;
  -webkit-appearance: none;
  color: inherit;
  margin: 0;
  text-align: center;
  background: none;
}
.CookiesNumButton{
  width: 0.85em;
  /* height: 1.1em; */
  background: v-bind('Theme.UserColors.fourth');
  display: flex;
  cursor: pointer;
  font-size: 1.75em;
  font-weight: 800;
  color: rgba(1,1,1,0.7);
  user-select: none;
}

.CookiesNumButton:hover{
  filter: brightness(1.15) drop-shadow(0px 0px 10px rgba(0,0,0,0.5));
}

.CookiesDarker{
  filter: brightness(0.6) drop-shadow(0px 0px 5px rgba(0,0,0,0.5));
}
.CookiesInputDisabled{
  opacity: 0.3;
  pointer-events: none;
  flex-wrap: wrap;
}
.ErrorBorderNumber{
  border: solid var(--colorr2) 2px;
}
</style>
