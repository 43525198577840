
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Placeholder: { default: undefined, type: String },
    Default: { default: undefined, type: String },
    Res: { type: String },
    ErrorData: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    CropWindow: { default: false, type: Boolean }
  },
  data () {
    return {
      Opened: false,
      Selecting: false,
      Loading: false,
      SelectedImgUrl: '',
      Dropping: false
    }
  },
  methods: {
    Clicked () {
      this.Selecting = true
    },
    PreventDragEvent (e :DragEvent) {
      e.preventDefault()
      e.stopPropagation()
    },
    FileDropped (e :DragEvent) {
      e.preventDefault()
      e.stopPropagation()
      var draggedData = e.dataTransfer
      if (draggedData) this.UserImageChanged(draggedData.files[0])
      this.Dropping = false
    },
    selectionDone () {
      setTimeout(() => {
        CheckLoading()
      }, 100)
      const _this = this
      function CheckLoading () {
        if (!_this.Loading) {
          _this.Selecting = false
        }
      }
    },
    UserImageChanged (DroppedFile?:any) {
      this.Loading = true
      var BeforeSelection = this.MEMRead(this.Res)
      var file
      if (DroppedFile) {
        file = DroppedFile
      } else {
        const Myevent: any = event
        file = Myevent.target.files[0]
      }
      if (file) {
        if (this.CropWindow) {
          this.OpenDialog('CookiesImageCropper', '', { Data: { File: file, Res: this.Res }, ParentThis: this }, { header: false, background: 'transparent', FullWindow: true })
        } else {
          this.MEMWrite(this.Res, file)
          this.SelectedImgUrl = URL.createObjectURL(file)
          this.MEMWrite(this.Res + '_ObjectURL', this.SelectedImgUrl)
          // this.MEMWrite(this.Res, this.SelectedImgUrl)
          this.$emit('selected', file)
        }
      } else {
        this.SelectedImgUrl = BeforeSelection
      }
      this.Loading = false
      this.Selecting = false
    },
    AfterCrop () {
      this.SelectedImgUrl = this.MEMRead(this.Res + '_ObjectURL')
      this.$emit('selected', this.MEMRead(this.Res))
    }
  },
  created () {
    if (this.Default !== undefined) {
      this.SelectedImgUrl = this.Default
    }
  },
  computed: {
    MemoryVal () {
      return this.MEMRead(this.Res)
    }
  },
  unmounted () {
    if (this.Res) this.MEMClean(this.Res)
  },
  watch: {
    MemoryVal: function (newVal, oldVal) { // watch it
      console.log(this.MEMRead(this.Res))
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "77f392ec": (_ctx.Theme.UserColors.main)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__