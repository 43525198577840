
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    // this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Attendance>
    }
  },
  computed: {
    // Buttons () :Array<FunctionalTableButton> {
    //   return [
    //     {
    //       Name: 'New Attendance',
    //       this: this,
    //       Function: 'NewAttendance',
    //       Icon: 'Add',
    //       Visible: this.Access('HR_Attendance_Create')
    //     }
    //   ]
    // },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['ID'],
            Sort: ['ID'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Slot',
            Name: 'Attendance',
            Path: ['Attendance'],
            Sort: ['Attendance'],
            Bold: true
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      var Dates = this.CurrentDay()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Employees', `?DateTime_From=${Dates.From}&DateTime_To=${Dates.To}`).then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Attendance'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    TooltipDesign (attendace :Attendance) {
      return `
        <div style="padding: 0.5em; display: flex; gap: 0.5em; flex-direction: column;">
          <div style="font-size:1em;">${this.Translate(attendace.Type)}</div>
          <div>${this.DateFormatter(attendace.DateTime, 'DD Month YYYY')}</div>
          <div>${this.DateFormatter(attendace.DateTime, 'Time')}</div>
        </div>
        `
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    }
  }
})
