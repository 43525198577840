
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: [] as Array<PayrollItemType>,
      ErrorsVisibilty: false,
      Accounts: [] as Array<Account>
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('PayrollItem Type') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_PayrollItemType_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Account',
            Type: 'DropDown',
            Value: 'AccountID',
            Sort: 'AccountID',
            Bold: true,
            DropDown_options: this.Accounts,
            DropDown_Type: 'Object',
            DropDown_Radio: true,
            DropDown_CanBeNone: true,
            DropDown_Height: '1.8em',
            DropDown_Align: 'start-flex',
            DropDown_Width: '6em',
            Res_ID: 'ID'
          },
          {
            Name: 'Type',
            Type: 'DropDown',
            Value: 'Type',
            Sort: 'Type',
            Bold: true,
            DropDown_options: ['Credit', 'Debit'],
            DropDown_Radio: true,
            DropDown_CanBeNone: true,
            DropDown_Height: '1.8em',
            DropDown_Align: 'start-flex',
            DropDown_Width: '6em',
            Res_ID: 'ID'
          },
          {
            Name: 'Percentage',
            Type: 'numberInput',
            Value: 'Percentage',
            Sort: 'Percentage',
            Res_ID: 'ID',
            numberInput_Step: 0.01,
            numberInput_Default: 0,
            Unit: this.Translate('%'),
            numberInput_IsPercentage: true
          },
          {
            Name: 'Fixed Value',
            Type: 'numberInput',
            Value: 'Fixed',
            Sort: 'Fixed',
            Res_ID: 'ID',
            numberInput_Step: 5,
            numberInput_Default: 0,
            Unit: this.PriceUnit
          },
          {
            Name: 'Editable',
            Type: 'checkBox',
            Value: 'IsCustomizable',
            Sort: 'IsCustomizable',
            Res_ID: 'ID'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      Table.Fields.push({
        Name: 'Delete',
        Value: '',
        Type: 'delete',
        NoHeader: true
      })
      return Table
    }
  },
  methods: {
    ReadAccounts () {
      this.Get('Finance_Accounts').then(response => {
        this.Accounts = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Positions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDialogData () {
      this.ReadPayrollItemType()
      this.ReadAccounts()
    },
    ReadPayrollItemType () {
      this.Loading.push('Loading')
      this.Get('HR_PayrollItemTypes').then(response => {
        this.CookiesData = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItem Type'
        this.OpenDialog('Error_Dialog', 'Error', error)
      })
    },
    NewPayrollItemType () {
      this.Post('POST', 'HR_PayrollItemTypes', {
        Name: this.DialogData.Name,
        // AccountID: this.DialogData.Account.ID.Value,
        Type: this.DialogData.Type,
        Percentage: this.DialogData.Percentage,
        Fixed: this.DialogData.Fixed,
        IsCustomizable: this.DialogData.IsCustomizable
      }).then(() => {
        this.ReadDialogData()
      })
    },
    FieldEdited (item :any) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :PayrollItemType) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      if (Object.keys(Patch).length === 0) {
        this.Saving.shift()
        return
      }
      this.Post('PATCH', 'HR_PayrollItemTypes', Patch, '/' + item.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.ReadPayrollItemType()
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    InputChanged (item :{ field :any, element :any, value: any }) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :PayrollItemType) => {
        return cat.ID === item.element.ID
      })
      this.DeepChanger(item.element, item.field.Value, item.value)
      var Patch = this.CookiesPatcher(Original, item.element)
      if (Object.keys(Patch).length === 0) {
        this.Saving.shift()
        return
      }
      this.Post('PATCH', 'HR_PayrollItemTypes', Patch, '/' + item.element.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    RemoveItem (Item :PayrollItemType, index:number) {
      if (!this.Access('HR_PayrollItemTypes_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_PayrollItemTypes', AddQuery: '/' + Item.ID, Text: 'Are you sure you want to delete <strong>' + Item.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: Item } as GeneralAcceptDialogData, {})
    },
    AfterRemove (Item :PayrollItemType) {
      this.ReadDialogData()
    }
  },
  mounted () {
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_PayrollItemType')
  }
})
