
const routes :Array<CookiesRouter> = [
  {
    path: '/Reports',
    name: 'Reports',
    meta: {
      Access: 'Reports_View' as AccessFeature
    },
    component: () => import('@/Apps/Reports/views/Reports.vue'),
    children: [
      {
        path: '/Reports/Inventory',
        name: 'Inventory Reports',
        component: () => import('@/Apps/Inventory/views/Reports/InventoryReports.vue'),
        meta: {
          Access: 'Inventory_View' as AccessFeature
        },
        children: [
          {
            path: '/Reports/Inventory/Exports',
            name: 'Export Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/Imports',
            name: 'Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/ExternalImports',
            name: 'External Imports Reports',
            component: () => import('@/Apps/Inventory/views/Reports/ExternalImportsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature,
              ReportsDateSelector: true
            }
          },
          {
            path: '/Reports/Inventory/Items',
            name: 'Items Reports',
            component: () => import('@/Apps/Inventory/views/Reports/InventoryItemsReports.vue'),
            meta: {
              Access: 'Inventory_Transfers_Read' as AccessFeature
            }
          }
        ]
      }
    ]
  }
]

export const ReportsRoutes = routes
