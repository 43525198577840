
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Pattern: require('@/assets/patterns/Pattern2.svg'),
      OriginalData: {} as any,
      Saving: [] as Array<string>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<string>,
      CookiesData: [] as Array<PayrollItemType>,
      ErrorsVisibilty: false,
      TableReady: false,
      Accounts: [] as Array<Account>,
      Shifts: [] as Array<Shift>,
      ShiftItems: [] as Array<ShiftItem>
    }
  },
  computed: {
    TableSettingsPayrollItemTypes () {
      var Table = {
        TableName: this.Translate('Payroll Item Type') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_PayrollItemType_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Step',
            Type: 'numberInput',
            Value: 'Step',
            Sort: 'Step',
            Res_ID: 'ID',
            numberInput_Step: 1,
            numberInput_Default: 0,
            numberInput_Max: this.CookiesData?.length,
            numberInput_Min: 0
          },
          {
            Name: 'Target Step',
            Type: 'numberInput',
            Value: 'TargetStep',
            Sort: 'TargetStep',
            Res_ID: 'ID',
            numberInput_Step: 1,
            numberInput_Default: 0,
            numberInput_Max: this.CookiesData?.length - 1,
            numberInput_Min: 0
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Main Account',
            Type: 'DropDown',
            Value: 'AccountID',
            Sort: 'AccountID',
            DropDown_options: this.Accounts,
            DropDown_Default: ['Account', 'Name'],
            DropDown_Type: 'Object',
            DropDown_Radio: true,
            // DropDown_CanBeNone: true,
            DropDown_Height: '1.8em',
            DropDown_Align: 'start-flex',
            DropDown_Width: '10em',
            Res_ID: 'ID'
          },
          {
            Name: 'Second Account',
            Type: 'DropDown',
            Value: 'SecondAccountID',
            Sort: 'SecondAccountID',
            DropDown_options: this.Accounts,
            DropDown_Default: ['SecondAccount', 'Name'],
            DropDown_Type: 'Object',
            DropDown_Radio: true,
            DropDown_CanBeNone: true,
            DropDown_Height: '1.8em',
            DropDown_Align: 'start-flex',
            DropDown_Width: '10em',
            Res_ID: 'ID'
          },
          {
            Name: 'Type',
            Type: 'DropDown',
            Value: 'Type',
            Sort: 'Type',
            DropDown_options: ['Credit', 'Debit'],
            DropDown_Default: 'Type',
            DropDown_Radio: true,
            // DropDown_CanBeNone: true,
            DropDown_Height: '1.8em',
            DropDown_Align: 'start-flex',
            DropDown_Width: '6em',
            Res_ID: 'ID'
          },
          {
            Name: 'Percentage',
            Type: 'numberInput',
            Value: 'Percentage',
            Sort: 'Percentage',
            Res_ID: 'ID',
            numberInput_Step: 0.01,
            numberInput_Default: 0,
            Unit: this.Translate('%'),
            numberInput_IsPercentage: true
          },
          {
            Name: 'Fixed Value',
            Type: 'numberInput',
            Value: 'Fixed',
            Sort: 'Fixed',
            Res_ID: 'ID',
            numberInput_Step: 5,
            numberInput_Default: 0,
            Unit: this.PriceUnit
          },
          {
            Name: 'Editable',
            Type: 'checkBox',
            Value: 'IsCustomizable',
            Sort: 'IsCustomizable',
            Res_ID: 'ID'
          },
          {
            Name: 'Calendar',
            Type: 'Calendar',
            Value: 'Calendar',
            Sort: 'Calendar',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Calendar',
            Type: 'icon',
            Value: 'Delayed' as IconsIDs,
            NoHeader: true,
            Class: 'F1_5em',
            Style: 'width: 3em',
            Function: 'EditCalendar',
            Visible: this.Access('HR_PayrollItemTypes_Edit'),
            ToolTip: this.Translate('Edit Calendar')
          },
          {
            Name: 'Delete',
            Type: 'icon',
            Value: 'Delete' as IconsIDs,
            NoHeader: true,
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 0,
              Operator: 'NOT EQUAL',
              ValuePath: 'PayrollItemsCount'
            },
            Class: 'F1_5em',
            Style: 'width: 3em',
            Function: 'DeleteRow',
            Visible: this.Access('HR_PayrollItemTypes_Remove'),
            ToolTip: this.Translate('Remove')
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Step'
        }
      } as CookiesTable
      return Table
    }
  },
  methods: {
    ReadAccounts () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Finance_Accounts').then(response => {
        this.Accounts = response
        this.TableReady = true
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        this.TableReady = true
        error.CookiesError = 'Error in reading Accounts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadShifts () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Shifts').then(response => {
        this.Shifts = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Shifts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    TableSettingsShiftItems (Shift :Shift) {
      var Table = {
        TableName: this.Translate('Shift Items') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: `D_ShiftItems_Table_${Shift.ID}`,
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Start Time',
            Type: 'DateInput',
            DateTimeInput_Type: 'time',
            Value: 'StartTime',
            Sort: 'StartTime',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Flex Time',
            Type: 'DateInput',
            DateTimeInput_Type: 'duration',
            Value: 'FlexTime',
            Sort: 'FlexTime',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Late Time',
            Type: 'DateInput',
            DateTimeInput_Type: 'time',
            Value: 'LateTime',
            Sort: 'LateTime',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Work Hours',
            Type: 'DateInput',
            DateTimeInput_Type: 'duration',
            Value: 'WorkHours',
            Sort: 'WorkHours',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Calendar',
            Type: 'Calendar',
            Value: 'Calendar',
            Sort: 'Calendar',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Edit Calendar',
            Type: 'icon',
            Value: 'Delayed' as IconsIDs,
            NoHeader: true,
            Class: 'F1_5em',
            Style: 'width: 3em',
            Function: 'EditCalendarShiftItem',
            Visible: this.Access('HR_ShiftItems_Edit'),
            ToolTip: this.Translate('Edit Calendar')
          },
          {
            Name: 'Delete',
            Type: 'icon',
            Value: 'Delete' as IconsIDs,
            NoHeader: true,
            Class: 'F1_5em',
            Style: 'width: 3em',
            Function: 'DeleteRow',
            Visible: this.Access('HR_ShiftItems_Remove'),
            ToolTip: this.Translate('Remove')
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    ReadPayrollItemType () {
      this.Loading.push('Loading')
      this.Get('HR_PayrollItemTypes').then(response => {
        this.CookiesData = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItem Type'
        this.OpenDialog('Error_Dialog', 'Error', error)
      })
    },
    ReadDialogData () {
      this.ReadPayrollItemType()
      this.ReadAccounts()
      this.ReadShifts()
    },
    NewPayrollItemType () {
      this.Post('POST', 'HR_PayrollItemTypes', {
        Name: this.DialogData.Name,
        Type: this.DialogData.Type,
        Percentage: this.DialogData.Percentage,
        Fixed: this.DialogData.Fixed,
        IsCustomizable: this.DialogData.IsCustomizable,
        Step: this.CookiesData?.length + 1,
        TargetStep: 1
      }).then(() => {
        this.ReadPayrollItemType()
      })
    },
    NewShift () {
      if (!this.Access('HR_Shifts_Create')) {
        return
      }
      this.OpenDialog('HR_Shifts_Dialog', this.Translate('New Shift'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditShift (Shift :Shift) {
      if (!this.Access('HR_Shifts_Edit')) {
        return
      }
      this.OpenDialog('HR_Shifts_Dialog', this.Translate('Edit Shift (' + Shift.Name + ')'), { Data: Shift, Editing: true }, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    DeleteShift (Shift :Shift) {
      if (!this.Access('HR_Shifts_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Shifts', AddQuery: '/' + Shift.ID, Text: 'Are you sure you want to delete <strong>' + Shift.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    NewShiftItem (Shift :Shift) {
      this.Post('POST', 'HR_ShiftItems', {
        ShiftID: Shift.ID
      }).then(() => {
        this.ReadShifts()
      })
    },
    FieldEdited (item :any) {
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((cat :PayrollItemType) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      if (Object.keys(Patch).length === 0) {
        this.Saving.shift()
        return
      }
      this.Post('PATCH', 'HR_PayrollItemTypes', Patch, '/' + item.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.ReadPayrollItemType()
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    FieldEditedShiftItem (item :any) {
      this.Get('HR_ShiftItems').then(response => {
        this.ShiftItems = response
      }, error => {
        error.CookiesError = 'Error in reading ShiftItems'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
      var Original = this.ShiftItems.find((ShiftItem :any) => {
        return ShiftItem.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      if (Object.keys(Patch).length === 0) {
        return
      }
      this.Post('PATCH', 'HR_ShiftItems', Patch, '/' + item.ID).then((response) => {
        this.ReadShifts()
      }, error => {
        console.log(error)
      })
    },
    InputChanged (item :{ field :any, element :any, value: any }) {
      // if (!item.value) item.value = null
      if (item?.field?.Value === 'AccountID' || item?.field?.Value === 'SecondAccountID') {
        item.value = item.value.ID
      }
      this.Saved = undefined
      this.Saving.push('Saving')
      var Original = this.CookiesData.find((data :PayrollItemType) => {
        return data.ID === item.element.ID
      })
      // this.DeepChanger(item.element, item.field.Value, item.value)
      // var Patch = this.CookiesPatcher(Original, item.element)
      // if (Object.keys(Patch).length === 0) {
      //   this.Saving.shift()
      //   return
      // }
      var Patch = {
        [item.field.Value]: item.value || item.value === 0 ? item.value : null
      }
      this.Post('PATCH', 'HR_PayrollItemTypes', Patch, '/' + item.element.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.ReadAccounts()
        this.ReadPayrollItemType()
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    InputChangedShiftItem (item :{ field :any, element :any, value: any }) {
      // this.Get('HR_ShiftItems').then(response => {
      //   this.ShiftItems = response
      // }, error => {
      //   error.CookiesError = 'Error in reading ShiftItems'
      //   this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      // })
      // var Original = this.ShiftItems.find((ShiftItem :ShiftItem) => {
      //   return ShiftItem.ID === item.element.ID
      // })
      // this.DeepChanger(item.element, item.field.Value, item.value)
      // var Patch = this.CookiesPatcher(Original, item.element)
      // if (Object.keys(Patch).length === 0) {
      //   return
      // }
      this.Post('PATCH', 'HR_ShiftItems', { [item.field.Value]: item.value }, '/' + item.element.ID).then((response) => {
        this.ReadShifts()
      }, error => {
        console.log(error)
      })
    },
    RemoveItem (Item :PayrollItemType, index:number) {
      if (!this.Access('HR_PayrollItemTypes_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_PayrollItemTypes', AddQuery: '/' + Item.ID, Text: 'Are you sure you want to delete <strong>' + Item.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: Item } as GeneralAcceptDialogData, {})
    },
    RemoveShiftItem (ShiftItem :any, index:number) {
      if (!this.Access('HR_ShiftItems_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_ShiftItems', AddQuery: '/' + ShiftItem.ID, Text: 'Are you sure you want to delete <strong>Shift Item</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.AfterRemove, AfterDialogFunctionValue: ShiftItem } as GeneralAcceptDialogData, {})
    },
    AfterRemove () {
      this.ReadDialogData()
    },
    EditCalendar (PayrollItemType :PayrollItemType) {
      if (!this.Access('HR_PayrollItemTypes_Read')) {
        return
      }
      this.OpenDialog('Calendar_Dialog', 'Edit Calendar', { Data: PayrollItemType, AfterDialogFunction: this.SaveCalendar, Editing: true }, {})
    },
    SaveCalendar (calendar :Calendar, payrollItem :PayrollItem) {
      this.Post('PATCH', 'HR_PayrollItemTypes', { Calendar: calendar }, '/' + payrollItem.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.$router.go(-1)
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    EditCalendarShiftItem (ShiftItem :ShiftItem) {
      if (!this.Access('HR_ShiftItems_Read')) {
        return
      }
      this.OpenDialog('Calendar_Dialog', 'Edit Calendar', { Data: ShiftItem, AfterDialogFunction: this.SaveCalendarShiftItem, Editing: true }, {})
    },
    SaveCalendarShiftItem (calendar :Calendar, ShiftItem :ShiftItem) {
      this.Post('PATCH', 'HR_ShiftItems', { Calendar: calendar }, '/' + ShiftItem.ID).then((response) => {
        this.Saving.shift()
        this.Saved = true
        this.$router.go(-1)
      }, error => {
        this.Saving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    }
  },
  mounted () {
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    }
  }
})
