
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as any,
      Saving: false,
      SavingTable: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      EditSave: false,
      Loading: [] as Array<'Loading'>,
      LoadingTable: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      Departments: [] as Array<Department>,
      Shifts: [] as Array<Shift>,
      CookiesData: [] as Array<PayrollItemType>
    }
  },
  methods: {
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'HR_Positions',
        OriginalData: this.OriginalData,
        PatchAPI: 'HR_Positions',
        Editing: this.DialogData.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    ReadDepartments () {
      this.Loading.push('Loading')
      this.Get('HR_Departments').then(response => {
        this.Departments = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Departments'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadShifts () {
      this.Get('HR_Shifts').then(response => {
        this.Shifts = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Shifts'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadPayrollItemTypes () {
      this.Loading.push('Loading')
      this.Get('HR_PayrollItemTypes').then(response => {
        this.CookiesData = response
        if (this.DialogData?.Editing) {
          this.Loading.push('Loading')
          this.Get('HR_PayrollItem', '?PositionID=' + this.DialogData?.Data?.ID).then(response2 => {
            response2.forEach((item :PayrollItem, index :number) => {
              this.MEMWrite(`D_PayrollItemType_Table_selector_${item.PayrollItemTypeID}`, true)
              if (item.PayrollItemType.IsCustomizable) {
                this.MEMWrite(`D_PayrollItemType_Table_Edited_Fixed Value_${item.PayrollItemTypeID}`, item.Fixed)
                this.MEMWrite(`D_PayrollItemType_Table_Edited_Percentage_${item.PayrollItemTypeID}`, item.Percentage)
              }
            })
            this.Loading.pop() // Remove Loading flag
          }, error => {
            this.Loading.pop() // Remove Loading flag
            error.CookiesError = 'Error in reading PayrollItem Type'
            this.OpenDialog('Error_Dialog', 'Error', error)
          })
        }
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItem Type'
        this.OpenDialog('Error_Dialog', 'Error', error)
      })
    },
    ReadDialogData () {
      this.ReadPayrollItemTypes()
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Payroll Item Types') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_PayrollItemType_Table',
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Type',
            Type: 'normal',
            Value: 'Type',
            Sort: 'Type',
            Bold: true,
            Res_ID: 'ID'
          },
          {
            Name: 'Percentage',
            Type: 'numberInput',
            Value: 'Percentage',
            Sort: 'Percentage',
            Res_ID: 'ID',
            numberInput_Step: 0.01,
            numberInput_Default: 0,
            Unit: this.Translate('%'),
            numberInput_IsPercentage: true,
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 0,
              Operator: 'EQUAL',
              ValuePath: 'IsCustomizable'
            }
          },
          {
            Name: 'Fixed Value',
            Type: 'numberInput',
            Value: 'Fixed',
            Sort: 'Fixed',
            Res_ID: 'ID',
            numberInput_Step: 5,
            numberInput_Default: 0,
            Unit: this.PriceUnit,
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 0,
              Operator: 'EQUAL',
              ValuePath: 'IsCustomizable'
            }
          },
          {
            Name: 'selector',
            Type: 'selector',
            Value: 'selector',
            Sort: 'selector',
            Res_ID: 'ID'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      return Table
    },
    ComputedCheckedPayrollItemTypes () {
      return this.MEMRead('D_PayrollItemType_Table')?.map((checkedItem: PayrollItemType) => {
        var CheckedItemPayrollItem = {
          PayrollItemTypeID: checkedItem.ID
        } as any as PayrollItemType
        if (checkedItem.IsCustomizable) {
          CheckedItemPayrollItem.Percentage = this.MEMRead(`D_PayrollItemType_Table_Edited_Percentage_${checkedItem.ID}`)
          CheckedItemPayrollItem.Fixed = this.MEMRead(`D_PayrollItemType_Table_Edited_Fixed Value_${checkedItem.ID}`)
        }
        return CheckedItemPayrollItem
      })
    },
    DialogMapper () {
      return [
        { Path: 'Name', Res: 'D_Position_Name' },
        { Path: ['Department', 'Name'], Res: 'D_Position_DepartmentName', ValuePath: 'ID', SavingPath: 'DepartmentID' },
        { Path: ['Shift', 'Name'], Res: 'D_Empolyee_Shift', ValuePath: 'ID', SavingPath: 'ShiftID' },
        { Path: 'PayrollItems', Value: this.ComputedCheckedPayrollItemTypes }
      ] as Array<DialogMapperObject>
    }
  },
  mounted () {
    this.ReadDialogData()
    this.ReadDepartments()
    this.ReadShifts()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
      this.Console(this.OriginalData)
    }
  },
  unmounted () {
    this.MEMClean('D_PayrollItemType')
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDepartments()
        this.ReadShifts()
      }
    }
  }
})
