<template>
  <div class="OrderTimeLine">
    <div>
      <div v-if="TimeLinesHistory === undefined" class="M-Auto TimeLineLoading"><CookiesLoading></CookiesLoading></div>
      <div v-else class= "timeline">
        <template v-for="(Timeline, index) in TimeLinesHistory" :key="(Timeline, index)">
          <div v-if="index" class="line ActiveLines" :style="`background-color: ${Timeline.Color};`"></div>
          <div class="OrderStage" :ToolTip="Timeline.Tooltip">
            <CookiesIcon class="" :ID="Timeline.Icon"></CookiesIcon>
          </div>
        </template>
      </div>
    </div>
  </div>
  </template>

<script>
export default {
  props: {
    TimeLinesHistory: { default: undefined, Type: Array }
  },
  methods: {
    HisIncludes (stat) {
      return this.TimeLinesHistory.some(element => {
        return element.Status === stat
      })
    }
  }
}
</script>

  <style scoped>
  .CookiesGeneralTableRow:hover > .OrderTimeLine > div{
    /* height: 100%; */
    transform: scaleY(1);
    opacity: 1;
  }
  .CookiesGeneralTableRow:hover > .OrderTimeLine > div > *{
    font-size: 1em;
  }
  .CookiesGeneralTableRow:hover > .OrderTimeLine > div > .TimeLineLoading{
    display: block;
  }
  .CookiesGeneralTableRow:hover{
    z-index: 5;
  }
  .OrderTimeLine{
    position: absolute;
    width: 80%;
    left:10% ;
    bottom: -4em;
    height: 4em;
    z-index:500;
    pointer-events: none;
  }
  .OrderTimeLine > div > *{
    font-size: 0em;
    transition: 0.2s font-size 0.2s ease;
  }
  .OrderTimeLine > div > .TimeLineLoading{
    display: none;
  }
  .OrderTimeLine > div{
    pointer-events: all;
    height: 100%;
    transform: scaleY(0);
    width: 100%;
    background: v-bind('Theme.BG7Fixed');
    position: relative;
    border-radius: 0 0 var(--rad2) var(--rad2);
    display: flex;
    transition: 0.3s transform 0s ease, 0.2s opacity 0s ease ;
    font-size: 1em;
    justify-content: center;
    justify-items: center;
    /* overflow: hidden; */
    /* filter: drop-shadow(var(--shadow1)); */
    opacity: 0;
    border-top: solid 1px v-bind('Theme.Shade1');
  }
  .timeline{
    margin: auto;
    display: flex;
    font-size: inherit;
    width: 80%;
  }
  .line{
    margin: auto -0.5em;
    width: 8em;
    flex: 5;
    height: 0.4em;
    border: solid 0.4em v-bind('Theme.shade3');
    background: v-bind('Theme.Shade1');
    filter: drop-shadow(0.2em 0.2em 0.8em rgba(0, 0, 0, 0.3));
    font-size: inherit;
  }
  .OrderStage{
    width: 3em;
    height: 3em;
    border-radius: 50%;
    background: v-bind('Theme.BG2Fixed');
    z-index: 5;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease;
    display: flex;
    font-size: inherit;
    position: relative;
    cursor: pointer;
  }
  .OrderStage > *{
    margin: auto;
    width: 70%;
    height: 70%;
    transition: transform 0.4s ease;
  }
  .OrderStage:hover{
    /* width: 3.5em;
    height: 3.5em; */
    transform: scale(1.2);
    filter: drop-shadow(var(--shadow0));
  }
  .OrderStage::before{
    opacity: 0.7;
    background: v-bind('Theme.BG6');
    filter: drop-shadow(var(--shadow1));
    content: '';
    border-radius: 20em;
    position: absolute;
    inset: 0;
    z-index: -8;
    transition: opacity 0.4s ease;
  }
  .OrderStage:hover::before{
    opacity: 1;
  }
  .OrderStage:hover > *{
    transform: scale(1.1);
  }
  .ActiveLines{
    background: v-bind('Theme.UserColors.third');
  }
  </style>
