import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "D-Flex",
  style: {"gap":"0.2em","justify-content":"center","align-items":"center"}
}
const _hoisted_2 = {
  key: 1,
  class: "F1_5em F-Bold"
}
const _hoisted_3 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesNumberInput = _resolveComponent("CookiesNumberInput")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.MinWidth? `'min-width:${_ctx.MinWidth};'` : '')
  }, [
    (_ctx.Type === 'duration')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.HoursMax > 0)
            ? (_openBlock(), _createBlock(_component_CookiesNumberInput, {
                key: 0,
                Default: 0,
                Res: _ctx.Res + '_hours',
                Step: 1,
                Max: _ctx.HoursMax,
                Min: 0,
                Unit: _ctx.Translate('hour'),
                style: {"margin":"0"}
              }, null, 8, ["Res", "Max", "Unit"]))
            : _createCommentVNode("", true),
          (_ctx.HoursMax > 0 && _ctx.MinutesMax > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " : "))
            : _createCommentVNode("", true),
          (_ctx.MinutesMax > 0)
            ? (_openBlock(), _createBlock(_component_CookiesNumberInput, {
                key: 2,
                Default: 0,
                style: {"margin":"0"},
                Step: 1,
                Max: _ctx.MinutesMax,
                Min: 0,
                Res: _ctx.Res + '_minutes',
                Unit: _ctx.Translate('min')
              }, null, 8, ["Max", "Res", "Unit"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.Translate(_ctx.Name)), 1),
          _withDirectives(_createElementVNode("input", {
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changed', _ctx.MEMRead(_ctx.Res)))),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$store.state.CookiesMemory[_ctx.Res]) = $event)),
            class: "CookiesInput color2 P_25em",
            type: _ctx.Type
          }, null, 40, _hoisted_3), [
            [_vModelDynamic, _ctx.$store.state.CookiesMemory[_ctx.Res]]
          ])
        ], 64))
  ], 4))
}