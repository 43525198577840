
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  // ---------------------------------------------------
  mixins: [CookiesCoreMixinVue],
  // ---------------------------------------------------
  props: {
    DialogIndex: Number,
    DialogData: { type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Saving: false,
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      TransferItems: [] as Array<any>,
      ExportItems: [] as Array<any>,
      AvailableItems: [] as any,
      ImportLocations: {} as any
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: 'BrandName'
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description',
            Class: 'F_75em'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'HistoryLocation',
            Class: 'F1_25em',
            Bold: true,
            Style: 'min-width: 11em'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    TableSettingsExport () {
      var Table = {
        TableName: this.Translate('Items'),
        CookiesID: 'D_TransferCheck_Table',
        ResID: 'ID',
        MultiLevel: true,
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'ItemName'
          },
          {
            Name: 'Brand',
            Type: 'normal',
            Value: 'BrandName'
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName'
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description',
            Class: 'F_75em'
          },
          {
            Name: 'Details',
            Type: 'normal',
            Value: 'Details'
          },
          {
            Name: 'Pack Size',
            Type: 'normal',
            Value: 'PackSize',
            Class: 'F1_25em'
          },
          {
            Name: 'Quantity',
            Type: 'normal',
            Value: 'Quantity',
            Bold: true,
            Class: 'F1_25em'
          },
          {
            Name: 'Location',
            Type: 'normal',
            Value: 'HistoryLocation',
            Class: 'F1_25em',
            Bold: true,
            Style: 'min-width: 11em'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'Code'
        }
      } as CookiesTable
      return Table
    },
    NotEnoughSpace () {
      var NoSpace = false
      var Locations = this.ImportLocations
      Object.keys(Locations).some(function (item) {
        var Found = Locations[item].find((element:any) => {
          console.log(element)
          return element.LocationFullPath === 'Not Enough Space'
        })
        console.log(Found)
        if (Found) {
          NoSpace = true
        }
      })
      return NoSpace
    },
    CombinedItems () {
      if (this.DialogData?.Data.Type !== 'Export' && this.DialogData?.Data.Type !== 'BranchesTransfer' && !this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_AlwaysCombineItems`]) {
        return this.TransferItems
      }
      var Combined :Array<any> = []
      if (!this.TransferItems) {
        return Combined
      }
      this.TransferItems.forEach(element => {
        var Found = Combined.find((added:any) => {
          console.log(element)
          return !element.IsUserSelect && added.ItemID === element.ItemID
        })
        if (Found) {
          Found.Quantity += element.IsPack ? element.Quantity * element.PackSize : element.Quantity
        } else {
          // var Name = element.Name ? element.Name : this.ObjectFinder()
          Combined.push({ ...element, Quantity: element.IsPack ? element.Quantity * element.PackSize : element.Quantity, PackSize: 1 })
        }
      })
      return Combined
    }
  },
  // ---------------------------------------------------
  methods: {
    ReadItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      var ItemsIds = this.TransferItems.map((item :any) => {
        return item.ItemID
      })
      if (!ItemsIds || ItemsIds.length === 0) {
        this.Loading.shift()
        return
      }
      this.Get('Inventory_Items', '?ID=[' + ItemsIds + ']' + '&ListAllWarehouses=true').then(response => {
        var ComputedItems = this.TransferItems
        if (!Array.isArray(response)) {
          response = [response]
        }
        this.TransferItems = ComputedItems.map((item :any) => {
          var CuurentItem = response.find((element :any) => {
            return element.ID === item.ItemID
          })
          if (CuurentItem) {
            item.Specifications = CuurentItem.Specifications
            item.Name = CuurentItem.Name
            item.Code = CuurentItem.Code
            item.CategoryName = CuurentItem.CategoryName
            item.BrandName = CuurentItem.BrandName
            if (item.WarehouseID && item.WarehouseID !== 'null' && item.SectionID && item.SectionID !== 'null') item.HistoryLocation = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
            item.ImportLocations = this.ImportLocations['Item_' + item.ItemID]
          }
          return item
        })
        // this.ExportItems = this.AvailableItems.map((item :any) => {
        //   var CuurentItem = response.find((element :any) => {
        //     return element.ID === item.ItemID
        //   })
        //   if (CuurentItem) {
        //     item.Specifications = CuurentItem.Specifications
        //     item.Name = CuurentItem.Name
        //     item.Code = CuurentItem.Code
        //     item.CategoryName = CuurentItem.CategoryName
        //     item.BrandName = CuurentItem.BrandName
        //     item.ExportLocation = item.WarehouseCode + (item.SectionLevel1 !== undefined ? (' > ' + item.SectionLevel1.Code) : '') + (item.SectionLevel2 !== undefined ? (' > ' + item.SectionLevel2.Code) : '') + ' > ' + item.SectionCode
        //   }
        //   return item
        // })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.AvailableItems = response
        console.log('AvailableItems---------------')
        console.log(this.AvailableItems)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransferItems () {
      if (this.DialogData === undefined || this.DialogData.Data === undefined) return
      this.Loading.push('Loading')
      this.Get('Inventory_TransferItems', '?TransferID=' + this.DialogData?.Data.ID).then(response => {
        this.TransferItems = response.map((value :any) => {
          if (!value.IsPack) {
            return {
              ...value,
              PackSize: 1
            }
          } else {
            return value
          }
        })
        this.ReadItems()
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Confirm () {
      this.Saving = true
      this.Post('POST', 'Inventory_Transfers_UPDATE', {}, '?ID=' + this.DialogData?.Data.ID + '&Stat=ImportCheck').then(() => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in updating Transfer'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var bodd = document.getElementById('TableToPrint')
      if (!bodd) return
      this.$store.state.Printing = bodd.cloneNode(true) as HTMLElement
    }
  },
  // ---------------------------------------------------
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.ReadAvailableItems()
    this.ReadTransferItems()
  },
  beforeUnmount () {},
  // ---------------------------------------------------
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        // refresh Data
      }
    }
  }
})
