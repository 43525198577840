
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      Loading: [] as Array<'Loading'>,
      ErrorsVisibilty: false,
      Employee: {} as Employee,
      CookiesDataAttendance: [] as Array<Attendance>,
      CookiesDataWarnings: [] as Array<Warning>,
      CookiesDataAttendanceRequests: [] as Array<AttendanceRequest>,
      CookiesDataDeductions: [] as Array<Deduction>,
      CookiesDataPayrollItems: [] as Array<PayrollItem>
    }
  },
  computed: {
    Tabs () {
      return [
        { Name: this.Translate('Attendance'), Value: 'Attendance', Access: this.Access('HR_Attendance_Read') },
        { Name: this.Translate('Warnings'), Value: 'Warnings', Access: this.Access('HR_Warnings_Read') },
        { Name: this.Translate('Deductions'), Value: 'Deductions', Access: this.Access('HR_Deductions_Read') },
        { Name: this.Translate('Requests'), Value: 'Requests', Access: this.Access('HR_AttendanceRequests_Read') },
        { Name: this.Translate('Payroll'), Value: 'Payroll', Access: this.Access('HR_PayrollItems_Read') }
      ] as Array<CookiesTabs>
    },
    TableSettingsAttendance () {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['Employee', 'ID'],
            Sort: ['Employee', 'ID'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name'],
            Bold: true
          },
          {
            Type: 'StatusBox',
            Name: 'Type',
            Path: 'Type',
            Sort: 'Type',
            StatusStyleColors: {
              CheckIn: '#3ED0A5',
              CheckOut: '#437A6A',
              LateCheckIn: '#FF3D3D',
              LeaveStart: '#AD55D6',
              LeaveEnd: '#8225AE',
              DayOff: '#306AFF',
              NoShow: '#FF0000',
              Holiday: '#636363',
              PublicHoliday: '#636363',
              Uncomplete: '#EA7912'
            },
            Bold: true
          },
          {
            Type: 'DateTime',
            Name: 'Time',
            Path: ['DateTime'],
            Sort: ['DateTime'],
            DateStyle: 'Time',
            Bold: true
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['DateTime'],
            Sort: ['DateTime'],
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              // {
              //   ID: 'Edit',
              //   Visible: this.Access('HR_Attendance_Edit'),
              //   ToolTip: this.Translate('Edit'),
              //   Emits: 'EditAttendance'
              // },
              // {
              //   ID: 'Delete',
              //   Visible: this.Access('HR_Attendance_Remove'),
              //   ToolTip: this.Translate('Remove'),
              //   Emits: 'DeleteAttendance'
              // }
            ]
          }
        ]
      }
    },
    TableSettingsAttendanceRequests () {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'ID',
            Path: ['EmployeeID'],
            Sort: ['EmployeeID'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type'],
            Sort: ['Type'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Status',
            Path: ['Status'],
            Sort: ['Status'],
            Bold: true
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['AddedDate'],
            Sort: ['AddedDate'],
            Bold: true
          }
        ]
      }
    },
    TableSettingsWarnings () {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Type',
            Path: ['Type', 'Name'],
            Sort: ['Type', 'Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name']
          },
          {
            Type: 'Normal',
            Name: 'Reason',
            Path: ['Reason'],
            Sort: ['Reason']
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          }
        ]
      }
    },
    TableSettingsDeductions () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Employee Name',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name']
          },
          {
            Type: 'Normal',
            Name: 'Reason',
            Path: ['Reason'],
            Sort: ['Reason']
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          },
          {
            Type: 'Normal',
            Name: 'Salary Deduct',
            Path: ['SalaryDeduction'],
            Sort: ['SalaryDeduction'],
            Unit: this.PriceUnit
          },
          {
            Type: 'Normal',
            Name: 'Hour Deduct',
            Path: ['HourDeduction'],
            Sort: ['HourDeduction'],
            Unit: this.Translate('Hour')
          },
          {
            Type: 'Normal',
            Name: 'Day Deduct',
            Path: ['DayDeduction'],
            Sort: ['DayDeduction'],
            Unit: this.Translate('Day')
          }
        ],
        TimeLine: {
          Path: 'WarningsTimeline'
        }
      }
    },
    TableSettingsPayrollItems () {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Name: 'Name',
            Type: 'Normal',
            Path: ['PayrollItemType', 'Name'],
            Sort: ['PayrollItemType', 'Name'],
            Bold: true
          },
          {
            Name: 'Account',
            Type: 'Normal',
            Path: ['Account', 'Name'],
            Sort: ['Account', 'Name']
          },
          {
            Name: 'Type',
            Type: 'Normal',
            Path: ['PayrollItemType', 'Type'],
            Sort: ['PayrollItemType', 'Type']
          },
          {
            Name: 'Percentage',
            Type: 'Normal',
            Path: 'Percentage',
            Sort: 'Percentage',
            Unit: '%'
          },
          {
            Name: 'Calendar',
            Type: 'Calendar',
            Path: 'Calendar',
            Sort: 'Calendar'
          },
          {
            Name: 'Fixed Value',
            Type: 'Normal',
            Path: 'Fixed',
            Sort: 'Fixed',
            Unit: this.PriceUnit
          }
          // {
          //   Name: 'Calendar',
          //   Type: 'Normal',
          //   Path: 'Delayed' as IconsIDs,
          //   Class: 'F1_5em',
          //   Style: 'width: 3em',
          //   Visible: this.Access('HR_PayrollItemTypes_Edit'),
          //   ToolTip: this.Translate('Edit Calendar')
          // }
        ]
      }
    },
    ChoosenTab () {
      return this.MEMRead('ChosenTab')
    }
  },
  methods: {
    ReadEmployee () {
      this.Loading.push('Loading')
      this.Get('HR_Employees', '?ID=' + this.$route.params.EmployeeID).then(response => {
        this.Employee = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Employees'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDialogData () {
      this.ReadAttendance()
      this.ReadWarnings()
      this.ReadDeductions()
      this.ReadAttendanceRequests()
      this.ReadPayrollItems()
    },
    ReadAttendance () {
      var Dates = this.CurrentMonth()
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Attendance', '?EmployeeID=' + this.$route.params.EmployeeID + `&DateTime_From=${Dates.From}&DateTime_To=${Dates.To}`).then(response => {
        this.CookiesDataAttendance = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Attendance'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadWarnings () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Warnings', '?EmployeeID=' + this.$route.params.EmployeeID).then(response => {
        this.CookiesDataWarnings = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Warnings'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDeductions () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Deductions', '?EmployeeID=' + this.$route.params.EmployeeID).then(response => {
        this.CookiesDataDeductions = response.map((value :Deduction) => {
          value.WarningsTimeline = value.Warnings?.map((warning) => {
            var Tooltip = `
            <span>Date: ${this.DateFormatter(warning.AddedDate)}</span><br/>
            <span>${this.Translate(warning.Reason)}</span>
            `
            return {
              Color: '#F4CE14',
              Icon: 'HasIssue',
              Tooltip: Tooltip
            } as TimeLine
          })
          var DeductionTooltip = `<span>Date: ${this.DateFormatter(value.AddedDate)}</span><br/>
            <span>${this.Translate('Deducted')}</span>`
          if (value.WarningsTimeline) {
            value.WarningsTimeline.push({
              Color: 'Red',
              Icon: 'Expenses',
              Tooltip: DeductionTooltip
            })
          }
          return value
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Deductions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAttendanceRequests () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_AttendanceRequest', '?EmployeeID=' + this.$route.params.EmployeeID).then(response => {
        this.CookiesDataAttendanceRequests = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading AttendanceRequests'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadPayrollItems () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_PayrollItem', '?EmployeeID=' + this.$route.params.EmployeeID).then(response => {
        this.CookiesDataPayrollItems = response.map((value :PayrollItem) => {
          return {
            ...value,
            Percentage: value.Percentage ? (value.Percentage * 100) : 0
          }
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading PayrollItems'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewAttendance () {
      if (!this.Access('HR_Attendance_Create')) {
        return
      }
      this.OpenDialog('HR_Attendance_Dialog', this.Translate('New Attendance'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    EditAttendance (Attendance :Attendance) {
      if (!this.Access('HR_Attendance_Read')) {
        return
      }
      this.OpenDialog('HR_Attendance_Dialog', 'Edit Attendance (' + Attendance.Type + ')', { Data: Attendance, Editing: true }, {})
    },
    DeleteAttendance (Attendance :Attendance) {
      if (!this.Access('HR_Attendance_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Attendance', AddQuery: '/' + Attendance.ID, Text: 'Are you sure you want to delete <strong>' + Attendance.Type + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    }
  },
  mounted () {
    this.ReadEmployee()
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    },
    ChoosenTab: function (newVal, oldVal) { // watch it
      this.ReadDialogData()
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c0344aac": (_ctx.Theme.UserColors.Gradient),
  "53f7ff09": (_ctx.Theme.UserColors.fourth_75)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__