
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Department>
    }
  },
  computed: {
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Department',
          this: this,
          Function: 'NewDepartment',
          Icon: 'Add',
          Visible: this.Access('HR_Departments_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Sort: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description'],
            Sort: ['Description'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Employees',
            Path: ['EmployeeCount'],
            Sort: ['EmployeeCount']
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_Departments_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditDepartment'
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Departments_Remove'),
                ToolTip: this.Translate('Delete'),
                Emits: 'DeleteDepartment',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: 0,
                  ValuePath: ['AllBranchesEmployeesCount']
                }
                // DynamicToolTip: ['Tooltip']
                // .map((value: Department) => {
                //   return {
                //     ...value,
                //     Tooltip: value.EmployeeCount === 0 ? this.Translate('Delete') : this.Translate('Cant Delete')
                //   }
                // })
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Departments_Remove'),
                ToolTip: this.Translate('Can`t Delete'),
                Emits: '',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: 0,
                  ValuePath: ['AllBranchesEmployeesCount']
                },
                Style: 'opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    NewDepartment () {
      if (!this.Access('HR_Departments_Create')) {
        return
      }
      this.OpenDialog('HR_Department_Dialog', this.Translate('New Department'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Departments').then(response => {
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Departments'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditDepartment (Department :Department) {
      if (!this.Access('HR_Departments_Read')) {
        return
      }
      this.OpenDialog('HR_Department_Dialog', 'Edit Department (' + Department.Name + ')', { Data: Department, Editing: true }, {})
    },
    DeleteDepartment (Department :Department) {
      if (!this.Access('HR_Departments_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Departments', AddQuery: '/' + Department.ID, Text: 'Are you sure you want to delete <strong>' + Department.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewDepartment':
          this.OpenDialog('HR_Department_Dialog', this.Translate('New Department'), {})
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
