
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      OriginalData: {} as Warehouse,
      Saving: false,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Sections: [] as Array<Section>,
      ItemsLocations: [] as any,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      Pattern: require('@/assets/patterns/Pattern2.svg'),
      DialogMapper: [
        { Path: 'Name', Res: 'D_Warehouse_Name', Default: '' },
        { Path: 'Code', Res: 'D_Warehouse_Code', Default: '' },
        { Path: 'Branch', Res: 'D_Warehouse_Branch', ValuePath: 'ID', SavingPath: 'BranchID' },
        { Path: 'Description', Res: 'D_Warehouse_Description', Default: '' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    SectionCalculator (section:Section, level: 'Main' | 'Level1' | 'Level2') {
      var Max = 0
      var InnerSections = this.LastLevelSections.filter((sec :any) => {
        return sec.SectionLevel1ID === section.ID || sec.SectionLevel2ID === section.ID
      })
      if (InnerSections.length > 0) {
        InnerSections.forEach(inner => {
          Max += inner.Volume
        })
      } else {
        Max = section.Volume
      }
      // ////////////////////////////////////
      var FilteredLocations = this.FilteredItemsLocations.filter((loc :any) => {
        return loc.SectionID === section.ID || loc.SectionLevel1ID === section.ID || loc.SectionLevel2ID === section.ID
      })
      var Available = 0
      var Reserved = 0
      var Ordered = 0
      FilteredLocations.forEach((lo :any) => {
        Available += lo.TotalAvailableQuantity * lo.ItemVolume
        Ordered += lo.TotalOrderedQuantity * lo.ItemVolume
        Reserved += lo.TotalReservedQuantity * lo.ItemVolume
      })
      // console.log(section)
      // console.log(section.ID)
      // console.log(FilteredLocations)
      var BarData :CookiesBar = {
        Max: Max,
        Min: 0,
        Values: [
          {
            Name: 'Available',
            Value: Available + Ordered + Reserved,
            Unit: 'm3'
          }
        ]
      }
      return BarData
    },
    ReadDialogData () {
      // this.ReadWarehouse()
      this.ReadSections()
      this.ReadItems()
    },
    ReadSections () {
      this.Loading.push('Loading')
      this.Get('Inventory_Sections', '?WarehouseID=' + this.DialogData.Data.ID).then(response => {
        this.Sections = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sections'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_ItemsLocation', '?WarehouseID=' + this.DialogData.Data.ID).then(response => {
        this.ItemsLocations = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Items'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      if (this.Saving) {
        return
      }
      this.Saving = true
      if (this.Requirments(this).Incomplete) {
        this.ErrorsVisibilty = true
        this.Saving = false
        return
      }
      if (!this.MEMRead('D_Warehouse_Branch')) {
        return
      }
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Inventory_Warehouses',
        OriginalData: this.OriginalData,
        PatchAPI: 'Inventory_Warehouses',
        Editing: this.DialogData.Editing,
        PatchQuery: this.DialogData.Editing ? '?ID=' + this.DialogData.Data.ID : ''
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    LevelSections (MatserSection :Section, level :number) {
      return this.Sections.filter(section => {
        return section[('SectionLevel' + level + 'ID' as 'SectionLevel1ID' | 'SectionLevel2ID')] === MatserSection.ID && (section[('SectionLevel' + (level + 1) + 'ID' as 'SectionLevel1ID' | 'SectionLevel2ID')] === null || section[('SectionLevel' + (level + 1) + 'ID' as 'SectionLevel1ID' | 'SectionLevel2ID')] === undefined)
      })
    },
    SectionItems (section: Section) {
      return this.ItemsLocations
      // return this.ItemsLocations.filter((itemlocation :any) => {
      //   return itemlocation.SectionID === section.ID
      // })
    },
    EditSection (e :Event, section: Section) {
      e.preventDefault()
      e.stopPropagation()
      this.OpenDialog('Inventory_Section_Dialog', this.Translate('Add New Section'), {
        Editing: true,
        WarehouseID: section.WarehouseID,
        SectionLevel1ID: section.SectionLevel1ID,
        SectionLevel2ID: section.SectionLevel2ID,
        Data: section
      })
    },
    RemoveSection (e :Event, section: Section) {
      e.preventDefault()
      e.stopPropagation()
      if (!this.Access('Inventory_Sections_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Inventory_Sections', AddQuery: '/' + section.ID, Text: 'Are you sure you want to delete <strong>' + section.Code + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    AddSection (sections :any) {
      this.OpenDialog('Inventory_Section_Dialog', this.Translate('Add New Section'), {
        WarehouseID: this.DialogData.Data.ID,
        SectionLevel1ID: sections.Level1 ? sections.Level1.ID : sections.Level1,
        SectionLevel2ID: sections.Level2 ? sections.Level2.ID : sections.Level2
      })
    },
    AddItem (section: Section) {
      this.OpenDialog('Inventory_ItemLocation_Dialog', this.Translate('Add New Item Location'), {
        SectionID: section.ID
      }, { NoBackgroundClose: true })
    },
    EditItem (item: InventoryItem) {
      this.OpenDialog('Inventory_Section_Dialog', this.Translate('Edit Item Location'), {
        Data: item
      })
    }
  },
  created () {
  },
  mounted () {
    if (this.DialogData.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data) as Warehouse
    }
    this.ReadDialogData()
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  unmounted () {
    this.MEMClean('D_Warehouse')
  },
  computed: {
    MainSections () {
      return this.Sections.filter(section => {
        return (!section.SectionLevel1ID && !section.SectionLevel2ID)
      })
    },
    FilteredItemsLocations () {
      if (!this.MEMRead('D_Warehouse_Search') || this.MEMRead('D_Warehouse_Search').length < 3 || this.MEMRead('D_Warehouse_Search') === '') {
        return this.ItemsLocations
      } else {
        return this.ItemsLocations.filter((loc :any) => {
          return this.CookiesSearcher(loc, this.MEMRead('D_Warehouse_Search'))
        })
      }
    },
    LastLevelSections () {
      return this.Sections.filter((sec :any) => {
        var Found = this.Sections.some(innersec => {
          return innersec.SectionLevel1ID === sec.ID || innersec.SectionLevel2ID === sec.ID
        })
        return !Found
      })
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadDialogData()
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "a8539a40": (_ctx.Theme.BG8),
  "a8539a4a": (_ctx.Theme.BG3),
  "1b1c1b52": (_ctx.Theme.Shade1),
  "a8539a48": (_ctx.Theme.BG4)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__