
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'
// import router from './Apps/ALL/router/index'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  components: {},
  data () {
    return {
      Configed: false
    }
  },
  created () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    this.MEMWrite('GlobalSaving', [])
    this.MEMWrite('GlobalSavingDone', [])
    this.MEMWrite('GlobalSavingFailed', [])
    fetch('/Cookies.config.json')
      .then((CustomConfigRes) => {
        if (CustomConfigRes.ok) {
          CustomConfigRes.json()
            .then((CustomConfig) => {
              console.log('CustomConfig')
              this.$store.state.CookiesCustomConfig = CustomConfig
              this.AfterCustomConfig()
            })
        }
      })
      .catch((error) => {
        console.error(error)
        this.AfterCustomConfig()
      })
  },
  beforeMount () {
    this.TimeBeforeMounted()
  },
  mounted () {
    const favicon = document.getElementById('FavIcon') as HTMLLinkElement
    favicon.href = this.$store.state.CookiesConfig.FavIco
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 300)
  },
  methods: {
    AfterCustomConfig () {
      var LocalConfig = this.LocalRead('CookiesDefaultConfig')
      if (LocalConfig !== null) {
        this.$store.state.CookiesConfig = {
          ...this.$store.state.CookiesConfig,
          ...LocalConfig
        }
        this.Configed = true
      }
      this.ReadSystemConfig()
      this.CookiesAuthorization()
    },
    ToolTipSence () {
      this.$store.state.ToolTipElement = document.querySelector('[ToolTip]:hover')
    },
    ReadSystemConfig () {
      this.ReadConfig().then((Configed) => {
        this.Configed = Configed as boolean
        if (!Configed) {
          var _this = this
          setTimeout(() => {
            _this.$store.state.AutoSignIn = true
            _this.ReadSystemConfig()
            _this.CookiesAuthorization()
          }, 10000)
        } else {
          console.log(this.$store.state.SystemManagment.CurrentSystems)
        }
      })
    }
  },
  computed: {
    TabHeaderShadower () {
      return this.$store.getters.CurrentTheme === 'light' ? 'rgba(0, 0, 0, 0.20)' : 'rgba(0, 0, 0, 0.17)'
    },
    Maintenance () {
      return this.$store.state.CookiesConfig.MaintenanceMode === 'true'
    },
    VersionChecker () {
      var ServerVersion = this.$store.state.CookiesVueServerVersion
      var CurrentVersion = this.$store.state.CookiesVersion
      if (!ServerVersion) return false
      if (parseInt(ServerVersion.split('.')[0]) > parseInt(CurrentVersion.split('.')[0])) return true
      if (parseInt(ServerVersion.split('.')[0]) < parseInt(CurrentVersion.split('.')[0])) return false
      if (parseInt(ServerVersion.split('.')[1]) > parseInt(CurrentVersion.split('.')[1])) return true
      if (parseInt(ServerVersion.split('.')[1]) < parseInt(CurrentVersion.split('.')[1])) return false
      if (parseInt(ServerVersion.split('.')[2]) > parseInt(CurrentVersion.split('.')[2])) return true
      return false
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b70d6dea": (_ctx.$store.getters.Direction),
  "68587d58": (_ctx.Theme.color3),
  "5caa3940": (_ctx.Theme.colorBold),
  "97fafcea": (_ctx.$store.getters.DefaultTextAlign),
  "57540b64": (_ctx.Theme.UserColors.main),
  "461ce942": (_ctx.Theme.UserColors.second),
  "d8d995c8": (_ctx.Theme.UserColors.third),
  "71509df6": (_ctx.Theme.UserColors.fourth),
  "68587d56": (_ctx.Theme.color1),
  "68587d57": (_ctx.Theme.color2),
  "06191d38": (_ctx.Theme.BG1),
  "06191d36": (_ctx.Theme.BG2),
  "06191d34": (_ctx.Theme.BG3),
  "06191d32": (_ctx.Theme.BG4),
  "06191d30": (_ctx.Theme.BG5),
  "06191d2e": (_ctx.Theme.BG6),
  "06191d2c": (_ctx.Theme.BG7),
  "06191d2a": (_ctx.Theme.BG8),
  "b5887720": (_ctx.Theme.BG1Fixed),
  "b21ec5e2": (_ctx.Theme.BG2Fixed),
  "aeb514a4": (_ctx.Theme.BG3Fixed),
  "ab4b6366": (_ctx.Theme.BG4Fixed),
  "a7e1b228": (_ctx.Theme.BG5Fixed),
  "a47800ea": (_ctx.Theme.BG6Fixed),
  "a10e4fac": (_ctx.Theme.BG7Fixed),
  "9da49e6e": (_ctx.Theme.BG8Fixed),
  "3f4d03fe": (_ctx.Theme.InputColor),
  "e60ab162": (_ctx.Theme.UserColors.Dimmed),
  "37b2937b": (_ctx.Theme.UserColors.Gradient),
  "4ca323eb": (_ctx.Theme.Shade0),
  "4ca323ec": (_ctx.Theme.Shade1),
  "4ca323ed": (_ctx.Theme.Shade2),
  "4ca323ee": (_ctx.Theme.Shade3),
  "4ca323ef": (_ctx.Theme.Shade4),
  "5be79836": (_ctx.Theme.BackBlur),
  "7d5af4fe": (_ctx.Theme.UserColors.main_25),
  "77b2d09e": (_ctx.Theme.UserColors.third_50),
  "40c21a25": (_ctx.Theme.Light3),
  "9b727a38": (_ctx.DirectionStyler.borderLeft),
  "d2300a42": (_ctx.DirectionStyler.borderRight),
  "07949bec": (_ctx.TabHeaderShadower),
  "b4217682": (_ctx.CurrentBackgroundColor),
  "4a00cb7f": (_ctx.DirectionStyler.first),
  "f2f27832": (_ctx.DirectionStyler.last),
  "519df746": (`url(${require("@/assets/elements/HorizontalEdgesMask.svg")})`)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__