
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: {} as any
  },
  data () {
    return {
      Acc: 0,
      OriginalData: {} as Section,
      Saving: false,
      SavingTable: [] as Array<'Saving'>,
      Loading: [] as Array<'Loading'>,
      Saved: undefined as boolean|undefined,
      CookiesData: {} as any,
      TransferItems: [] as any,
      AvailableItems: [] as any,
      Items: [] as any,
      Brands: [] as any,
      Categories: [] as any,
      Branches: [] as any,
      CookiesSpecs: [] as any,
      TransferSources: [] as any,
      AddedItem: false,
      ErrorsVisibilty: false,
      CurrentImage: undefined as File | undefined,
      DialogMapper: [
        { Path: 'Items', Res: 'D_Transfer_Items', Default: [] },
        { Path: 'CustomerName', Res: 'D_Transfer_Customer', Default: '' },
        { Path: 'CustomerPhone', Res: 'D_Transfer_CustomerPhone', Default: '' },
        { Path: 'VehicleNumber', Res: 'D_Transfer_VehicleNumber', Default: '' },
        { Path: 'TransferNO', Res: 'D_Transfer_TransferNO' },
        { Path: 'Type', Value: this.DialogData.Type },
        { Path: 'Notes', Res: 'D_Transfer_Note', Default: '' },
        { Path: 'SupplierID', Res: 'D_Transfers_ImportSource', ValuePath: 'ID', Default: null },
        { Path: 'ContainerShip', Res: 'D_Transfer_ContainerShip', Default: '' },
        { Path: 'BranchID', Value: this.$store.getters.BranchID },
        { Path: 'SupplierName', Res: 'D_Transfers_SupplierName' },
        { Path: 'DestinationBranch', Res: 'D_Transfers_DestinationBranch', ValuePath: 'ID' },
        { Path: 'Status', Res: 'D_Transfer_Status' }
      ] as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData (InistialTime = false) {
      this.ReadItems(InistialTime)
      this.ReadTransfer()
      this.ReadTransferSources()
      this.ReadAvailableItems()
      this.ReadBrands()
      this.ReadCategories()
      this.ReadBranches()
    },
    SaveLocal () {
      console.log('save')
    },
    ShowLocations (Item :any) {
      // console.log(Item)
      // console.log(this.DecodedAval)
      var ItemAval = this.DecodedAval.filter((item :any) => {
        return Item.ItemID === item.ItemID
      })
      // console.log(ItemAval)
      this.ViewLocDialog(ItemAval, Item.ItemID)
    },
    ChooseLocations (Item :any) {
      // console.log(Item)
      // console.log(this.DecodedAval)
      var ItemAval = this.DecodedAval.filter((item :any) => {
        return Item.ItemID === item.ItemID
      })
      // console.log(ItemAval)
      var QntySelected = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + Item.ResID)
      this.OpenDialog('Inventory_CustomExportLocation_Dialog', 'Choose Location', { Data: ItemAval, Quantity: QntySelected, ParentThis: this, ItemID: Item.ItemID }, {})
    },
    ViewLocDialog (ItemAval :any, ID: number) {
      this.OpenDialog('Inventory_AvailablItemLocations_Dialog', 'Locations', { Data: ItemAval, ItemID: ID }, { NoBackgroundClose: true, header: false })
    },
    RemoveItem (Item :any) {
      this.TransferItems.splice(Item.OriginalIndex, 1)
      this.SaveLocal()
    },
    ReadItems (InistialTime = false) {
      this.Loading.push('Loading')
      this.Get('Inventory_Items').then(response => {
        this.Items = this.GeneralSort(response, 'Code', 1)
        try {
          if (InistialTime && this.CookiesData && this.CookiesData.Items && this.CookiesData.Items.length > 0) {
            this.CookiesData.Items.forEach((transitem :any) => {
              var Found = this.Items.find((item:any) => {
                return item.ID === transitem.ItemID
              })
              if (Found) {
                Found.AvailableQuantity += transitem.Quantity
                this.TransferItems.push(this.DeepCloner({
                  ...Found,
                  ...transitem,
                  ResID: transitem.ItemID + '_' + this.Acc
                }))
                this.Acc += 1
              }
            })
          }
        } catch (error) {
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAvailableItems () {
      this.Loading.push('Loading')
      this.Get('Inventory_AvailableItems', '?Type=Available').then(response => {
        this.AvailableItems = response
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCategories () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Items').then(response => {
        this.Categories = this.GeneralSort(response, 'Name', 1)
        // if (this.DialogData.Data && this.DialogData.Data.CategoryID) {
        //   var SelectedCategory = this.Categories.find((cat :any) => {
        //     return cat.ID === this.DialogData.Data.CategoryID
        //   })
        //   if (SelectedCategory) {
        //     this.MEMWrite('D_Item_Category_Value', SelectedCategory)
        //     this.MEMWrite('D_Item_Category', SelectedCategory.Name)
        //   }
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBranches () {
      this.Loading.push('Loading')
      this.Get('Branches', '?TransferBranches=true').then(response => {
        this.Branches = this.GeneralSort(response, 'Name', 1)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadBrands () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=Brands').then(response => {
        this.Brands = this.GeneralSort(response, 'Name', 1)
        // if (this.DialogData.Data && this.DialogData.Data.BrandID) {
        //   var SelectedBrand = this.Brands.find((brand :any) => {
        //     return brand.ID === this.DialogData.Data.BrandID
        //   })
        //   if (SelectedBrand) {
        //     this.MEMWrite('D_Item_Brand_Value', SelectedBrand)
        //     this.MEMWrite('D_Item_Brand', SelectedBrand.Name)
        //   }
        // }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Categories'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveData () {
      if (this.DialogData.Editing) {
        this.Saving = true
        var EditedItems :Array<any> = []
        EditedItems = this.TransferItems.map((item :any) => {
          var Location = item.Location
          if (item.Location && !item.Location[0].AvailID) {
            Location = undefined
          }
          return {
            ...item,
            ItemID: item.ItemID,
            ItemName: item.Name,
            ItemCode: item.Code,
            IsUserSelect: item.IsUserSelect,
            CustomLocation: Location,
            AvalID: item.AvalID,
            IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
            PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
            Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
            Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID)
          }
        })
        var EditedSupplier = this.MEMRead('D_Transfers_ImportSource_Value')
        if (EditedSupplier && EditedSupplier.Name) {
          this.MEMWrite('D_Transfers_SupplierName', EditedSupplier.Name)
        }
        var EditedBranch = this.MEMRead('D_Transfers_DestinationBranch_Value')
        if (EditedBranch && EditedBranch.Name) {
          this.MEMWrite('D_Transfer_Customer', `شركة الصعيدي/ ${EditedBranch.Name}`)
        }
        this.MEMWrite('D_Transfer_Items', EditedItems)
        this.Post('PATCH', 'Inventory_Transfers', (this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') ? { New: this.DialogDataDecoder(this.DialogMapper, true), Old: this.CookiesData } : this.DialogDataDecoder(this.DialogMapper, true), '?ID=' + this.DialogData.Data.ID + '&Stat=' + (this.DialogData.Type === 'BranchesTransfer' ? 'Export' : this.DialogData.Type) + 'Edit').then(() => {
          this.Saving = false
          this.$router.go(-1)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Editing Item'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
        return
      }
      var SelectedItems :Array<any> = []
      var Supplier = this.MEMRead('D_Transfers_ImportSource_Value')
      if (Supplier && Supplier.Name) {
        this.MEMWrite('D_Transfers_SupplierName', Supplier.Name)
      }
      switch (this.DialogData.Type) {
        case 'Export':
          SelectedItems = []
          SelectedItems = this.TransferItems.map((item :any) => {
            var Location = item.Location
            if (item.Location && !item.Location[0].AvailID) {
              Location = undefined
            }
            return {
              ItemID: item.ItemID,
              ItemName: item.Name,
              ItemCode: item.Code,
              IsUserSelect: item.IsUserSelect,
              CustomLocation: Location,
              AvalID: item.AvalID,
              IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
              PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
              Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
              Details: item.Details
            }
          })
          // console.log('this.$store.state.UserManagment')
          // console.log(this.$store.state.UserManagment)
          var CurrentUserRoles = this.$store.state.UserManagment.CurrentUser.RoleNames
          var Query = ''
          if (CurrentUserRoles.indexOf('SuperAdmin') || CurrentUserRoles.indexOf('Warehouses_Access_All')) {
            Query = ''
          } else {
            CurrentUserRoles.forEach((element:any) => {
              if (element.indexOf('Warehouses_Access_9') === 'khaled') {
                if (Query === '') {
                  Query = '?WarhouseID=' + element.split('_')[2]
                } else {
                  Query += ',' + element.split('_')[2]
                }
              }
            })
          }
          // console.log('Query')
          // console.log(Query)
          this.MEMWrite('D_Transfer_Items', SelectedItems)
          this.MEMWrite('D_Transfer_Status', (this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_NoPrintBeforeExporting`] === 'true') ? 'Exporting' : 'New')
          this.SaveDialogData({
            DialogMapper: this.DialogMapper,
            Parentthis: this,
            // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
            PostAPI: 'Inventory_Transfers_Export',
            PostQuery: Query,
            OriginalData: this.OriginalData,
            PatchAPI: 'Inventory_Transfers',
            Editing: this.DialogData.Editing,
            PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID + '&Stat=ExportEdit' : ''
          })
          break
        case 'BranchesTransfer':
          SelectedItems = []
          SelectedItems = this.TransferItems.map((item :any) => {
            var Location = item.Location
            if (item.Location && !item.Location[0].AvailID) {
              Location = undefined
            }
            return {
              ItemID: item.ItemID,
              ItemName: item.Name,
              ItemCode: item.Code,
              IsUserSelect: item.IsUserSelect,
              CustomLocation: Location,
              AvalID: item.AvalID,
              IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
              PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
              Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
              Details: item.Details
            }
          })
          // console.log('this.$store.state.UserManagment')
          // console.log(this.$store.state.UserManagment)
          var CurrentUserRolesExternal = this.$store.state.UserManagment.CurrentUser.RoleNames
          var QueryExternal = ''
          if (CurrentUserRolesExternal.indexOf('SuperAdmin') || CurrentUserRolesExternal.indexOf('Warehouses_Access_All')) {
            QueryExternal = ''
          } else {
            CurrentUserRolesExternal.forEach((element:any) => {
              if (element.indexOf('Warehouses_Access_9') === 'khaled') {
                if (QueryExternal === '') {
                  QueryExternal = '?WarhouseID=' + element.split('_')[2]
                } else {
                  QueryExternal += ',' + element.split('_')[2]
                }
              }
            })
          }
          // console.log('QueryExternal')
          // console.log(QueryExternal)
          this.MEMWrite('D_Transfer_Items', SelectedItems)
          var Branch = this.MEMRead('D_Transfers_DestinationBranch_Value')
          if (Branch && Branch.Name) {
            this.MEMWrite('D_Transfer_Customer', `شركة الصعيدي/ ${Branch.Name}`)
            this.MEMWrite('D_Transfers_SupplierName', `شركة الصعيدي/ ${this.$store.state.BranchManagment.CurrentBranch?.Name}`)
          }
          this.SaveDialogData({
            DialogMapper: this.DialogMapper,
            Parentthis: this,
            // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
            PostAPI: 'Inventory_Transfers_BranchesTransfer',
            PostQuery: QueryExternal,
            OriginalData: this.OriginalData,
            PatchAPI: 'Inventory_Transfers',
            Editing: this.DialogData.Editing,
            PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID + '&Stat=ExportEdit' : ''
          })
          break
        case 'Import':
          SelectedItems = this.TransferItems.map((item :any) => {
            return {
              ItemID: item.ItemID,
              ItemName: item.Name,
              ItemCode: item.Code,
              IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
              PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
              Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
              Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID)
            }
          })
          this.MEMWrite('D_Transfer_Items', SelectedItems)
          this.SaveDialogData({
            DialogMapper: this.DialogMapper,
            Parentthis: this,
            // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
            PostAPI: 'Inventory_Transfers_Import',
            OriginalData: this.OriginalData,
            PatchAPI: 'Inventory_Transfers',
            Editing: this.DialogData.Editing,
            PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID + '&Stat=ImportEdit' : ''
          })
          break
        case 'ExternalImport':
          SelectedItems = this.TransferItems.map((item :any) => {
            return {
              ItemID: item.ItemID,
              ItemName: item.Name,
              ItemCode: item.Code,
              IsPack: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID),
              PackSize: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack' + '_' + item.ResID) ? this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Pack Size' + '_' + item.ResID) : 1,
              Quantity: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Quantity' + '_' + item.ResID),
              Details: this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + 'Details' + '_' + item.ResID)
            }
          })
          this.MEMWrite('D_Transfer_Items', SelectedItems)
          this.SaveDialogData({
            DialogMapper: this.DialogMapper,
            Parentthis: this,
            // PostAPI: 'Inventory_Transfers_' + this.DialogData.Type as 'Inventory_Transfers_Import' | 'Inventory_Transfers_Export',
            PostAPI: 'Inventory_Transfers_Import',
            OriginalData: this.OriginalData,
            PatchAPI: 'Inventory_Transfers',
            Editing: this.DialogData.Editing,
            PatchQuery: this.DialogData.Editing ? '?ID=' + this.CookiesData.ID + '&Stat=ImportEdit' : ''
          })
          break
      }
    },
    InputChanged (Changed :any) {
      if (!this.DialogData.Editing) {
        return
      }
      var CurrentValue = this.MEMRead(this.TableSettings.CookiesID + '_Edited_' + Changed.field.Name + '_' + Changed.element.ResID)
      if (this.CookiesData.Items && this.CookiesData.Items.length > 0) {
        var Found = this.CookiesData.Items.find((item :any) => {
          return item.ItemID === Changed.element.ItemID
        })
        var OriginalValue = Found[Changed.field.Value]
      }
    },
    CheckItem (Item: InventoryItem) {
      if ((this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') && !Item.AvailableQuantity) {
        this.MEMClean('D_Transfer_Item', true)
        return
      }
      if ((this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') && Item.IsUserSelect && !this.NoUserSelectBranchConfig) {
        var Available = this.AvailableItems.filter((aval :any) => {
          return aval.ItemID === Item.ID
        })
        var Modified = Available.map((aval :any) => {
          return { ...aval, ItemName: Item.Name, ItemCode: Item.Code }
        })
        this.OpenDialog('Inventory_UserSelectItem_Dialog', this.Translate('Select Items'), { Data: { Item: Item, AvailableItems: Modified, this: this } })
        return
      }
      this.AddItem(Item)
    },
    AddItem (Item: any) {
      var found = this.TransferItems.find((element :any) => {
        return Item.IsUserSelect && !this.NoUserSelectBranchConfig ? element.AvalID === Item.AvalID : Item.ID === element.ItemID
      })
      if ((this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') && found) {
        this.MEMClean('D_Transfer_Item', true)
        return
      }
      this.AddedItem = false
      var ModifiedItem :any = { ...Item }
      ModifiedItem.Location = [
        {
          Path: this.Translate('Auto')
        }
      ]
      ModifiedItem.ItemID = Item.ID
      ModifiedItem.ID = undefined
      ModifiedItem.ResID = ModifiedItem.ID + '_' + this.Acc
      ModifiedItem.IsPack = false
      if (!ModifiedItem.IsUserSelect || this.NoUserSelectBranchConfig) ModifiedItem.Quantity = 1
      this.TransferItems.push(this.DeepCloner(ModifiedItem))
      this.Acc += 1
      this.MEMClean('D_Transfer_Item', true)
      // setTimeout(() => {
      //   const scrollpoint = document.getElementById('scrollpoint')
      //   if (scrollpoint) {
      //     scrollpoint.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      //   }
      // }, 100)
      setTimeout(() => {
        this.AddedItem = true
      }, 10)
      // setTimeout(() => {
      //   this.AddedItem = false
      // }, 2000)
      // console.log(Item.Quantity)
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    EditCategories (Type: string, Name: string) {
      this.OpenDialog('Main_Categories_Dialog', this.Translate('Edit ' + Name), { Data: {}, Type: Type })
    },
    ReadTransferSources () {
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=TransferSources').then(response => {
        this.TransferSources = response
        // console.log(this.CookiesData)
        if (this.CookiesData && this.CookiesData.SupplierID) {
          var SelectedSource = this.TransferSources.find((Source :any) => {
            return Source.ID === this.CookiesData.SupplierID
          })
          if (SelectedSource) {
            this.MEMWrite('D_Transfers_ImportSource_Value', SelectedSource)
            this.MEMWrite('D_Transfers_ImportSource', SelectedSource.Name)
          }
        } else {
          this.MEMClean('D_Transfers_ImportSource')
        }
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadTransfer () {
      if (!this.DialogData.Editing || this.DialogData.Data.ID) {
        return
      }
      this.Loading.push('Loading')
      this.Get('Inventory_Transfers', `ID=${this.DialogData.Data.ID}`).then(response => {
        this.CookiesData = response
        // console.log(this.CookiesData)
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Sources'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  created () {
    console.log(this.DialogData.Data)
    this.CookiesData = this.DialogData.Data
    console.log(this.DialogData.Data)
    // combin items
    if (this.DialogData?.Data.Type === 'Export' || this.DialogData?.Type === 'BranchesTransfer') {
      var Combined :Array<any> = []
      if (this.CookiesData.Items) {
        this.CookiesData.Items.forEach((item :any) => {
          var Found = Combined.find((added:any) => {
            return (!item.IsUserSelect || this.NoUserSelectBranchConfig) && added.ItemID === item.ItemID
          })
          if (Found) {
            Found.Quantity += item.IsPack ? item.Quantity * item.PackSize : item.Quantity
          } else {
            // var Name = element.Name ? element.Name : this.ObjectFinder()
            Combined.push({ ...item, Quantity: item.IsPack ? item.Quantity * item.PackSize : item.Quantity, PackSize: 1 })
          }
        })
      }
      this.CookiesData.Items = Combined
    }
  },
  mounted () {
    if (this.CookiesData) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.CookiesData) as Section
    }
    this.ReadDialogData(true)
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData.Type === 'BranchesTransfer' && this.DialogData.Data.DestinationBranch && this.$store.state.BranchManagment.AvailableBranches) {
      var DestinationBranch = this.ObjectFinder(this.$store.state.BranchManagment.AvailableBranches, ['ID'], this.DialogData.Data.DestinationBranch)
      console.log(DestinationBranch)
      if (DestinationBranch) {
        this.MEMWrite('D_Transfers_DestinationBranch_Value', DestinationBranch)
        this.MEMWrite('D_Transfers_DestinationBranch', DestinationBranch.Name)
      }
    }
  },
  unmounted () {
    this.MEMClean('D_Transfer')
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('TransferDIalogItems') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_Transfer_Table',
        MultiLevel: true,
        ResID: 'ID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: 'Item',
            Type: 'normal',
            Value: 'Code',
            Bold: true
          },
          {
            Name: 'Name',
            Type: 'normal',
            Value: 'Name',
            Bold: true
          },
          {
            Name: 'Category',
            Type: 'normal',
            Value: 'CategoryName',
            Bold: true
          },
          {
            Name: 'Specs',
            Type: 'array-normal',
            Value: 'Specifications',
            MultiValue: 'Description'
          },
          {
            Name: 'Available',
            Type: 'normal',
            Value: 'AvailableQuantity',
            Bold: true,
            Visible: this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer'
          },
          {
            Name: 'Pack',
            Type: 'checkBox',
            Value: 'IsPack',
            Res_ID: 'ResID',
            // Default: false,
            Visible: this.DialogData.Type === 'Import' || this.DialogData.Type === 'ExternalImport',
            DynamicClass: {
              ClassList: 'Disabled',
              FixedValue: 1,
              Operator: 'EQUAL',
              ValuePath: 'IsUserSelect'
            }
          },
          {
            Name: 'Pack Size',
            Type: 'numberInput',
            Value: 'PackSize',
            Res_ID: 'ResID',
            RelativeClass: {
              ClassValue: 'Disabled',
              FixedValue: false,
              Operator: 'EQUAL',
              RelName: 'Pack',
              RelResID: 'ResID',
              RelValue: 'IsPack'
            },
            Visible: this.DialogData.Type === 'Import' || this.DialogData.Type === 'ExternalImport'
          },
          {
            Name: 'Quantity',
            Type: 'numberInput',
            numberInput_Default: 1,
            numberInput_Min: 0,
            Value: 'Quantity',
            Res_ID: 'ResID',
            numberInput_Max: this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer' ? 'AvailableQuantity' : undefined,
            DynamicClass: {
              ClassList: (this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') && !this.NoUserSelectBranchConfig ? 'Disabled' : '',
              FixedValue: 1,
              Operator: 'EQUAL',
              ValuePath: 'IsUserSelect'
            }
          },
          {
            Name: '',
            Type: 'icon',
            Value: 'Search' as IconsIDs,
            Function: 'ShowLocations',
            ToolTip: 'See Available',
            DynamicClass: {
              ClassList: !this.NoUserSelectBranchConfig ? 'Disabled' : '',
              FixedValue: 1,
              Operator: 'EQUAL',
              ValuePath: 'IsUserSelect'
            }
          },
          {
            Name: 'Details',
            Type: 'normal',
            Editable: this.DialogData.Type === 'Import' || this.DialogData.Type === 'ExternalImport',
            Value: 'Details',
            Res_ID: 'ResID'
          },
          {
            Name: 'Location',
            Type: 'array-normal',
            MultiValue: 'Path',
            Visible: this.Access('Inventory_Transfers_CustomExport') && !this.DialogData.Editing && (this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer'),
            Value: 'Location',
            Res_ID: 'ResID'
          },
          {
            Name: '',
            Type: 'icon',
            Value: 'Edited' as IconsIDs,
            Function: 'ChooseLocations',
            ToolTip: 'Choose Location',
            Visible: this.Access('Inventory_Transfers_CustomExport') && !this.DialogData.Editing && (this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer'),
            DynamicClass: {
              ClassList: !this.NoUserSelectBranchConfig ? 'Disabled' : '',
              FixedValue: 1,
              Operator: 'EQUAL',
              ValuePath: 'IsUserSelect'
            }
          },
          {
            Name: 'Delete',
            Value: '',
            Type: 'delete',
            NoHeader: true
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'SorterIndex'
        }
      } as CookiesTable
      return Table
    },
    ComputedSelectors () {
      if (this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      } else {
        return [['Code'], ['Name'], ['Specifications', 0, 'Description'], ['Specifications', 1, 'Description']]
      }
    },
    ExportItems () : Array<any> {
      var MappedItems :Array<any> = this.DeepCloner(this.TransferItems)
      var NewItems = MappedItems.map((item:any) => {
        var ItemAvailableAll = this.AvailableItems.filter((availItem :any) => {
          return item.ID === availItem.ItemID && availItem.IsPack === this.MEMRead('D_Transfer_IsPack') && availItem.Type === 'Available'
        })
        var DecodedAvailable :Array<any> = []
        ItemAvailableAll.forEach((element :any) => {
          var Same = DecodedAvailable.find(Decoded => {
            return Decoded.IsPack === element.IsPack && Decoded.PackSize === element.PackSize
          })
          if (Same) {
            Same.Quantity += element.Quantity
          } else {
            DecodedAvailable.push({
              Total: element.PackSize * this.MEMRead('D_ExportTransfer_Table_' + element.ItemID + '_Edited_Quantity_' + element.ID),
              ...this.DeepCloner(element)
            })
          }
        })
        item.AvailableItems = DecodedAvailable
        return item
      })
      return NewItems
    },
    FilteredItems () {
      var Filteredd :any = []
      var IsSearch = this.MEMRead('D_Transfer_Search') && this.MEMRead('D_Transfer_Search').length > 2
      var IsSearchByCode = this.MEMRead('D_Transfer_SearchByCode') && this.MEMRead('D_Transfer_SearchByCode').length > 1
      var IsBrand = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Brand').ID !== undefined
      var IsCategory = this.MEMRead('D_Transfer_Brand') && this.MEMRead('D_Transfer_Category').ID !== undefined
      var Items = this.Items
      var TransferItems = this.TransferItems
      Filteredd = Items.filter((item :any) => {
        var ItemSelected = false
        if ((this.DialogData.Type === 'Export' || this.DialogData.Type === 'BranchesTransfer') && (!item.IsUserSelect || this.NoUserSelectBranchConfig)) {
          ItemSelected = TransferItems.some((element :any) => {
            return (item.ID === element.ItemID)
          })
        }
        var Search = this.MEMRead('D_Transfer_Search') === '' || this.MEMRead('D_Transfer_Search').length < 3 || this.CookiesSearcher(item, this.MEMRead('D_Transfer_Search'))
        var SearchByCode = this.MEMRead('D_Transfer_SearchByCode') === '' || this.CookiesSearcher(item, this.MEMRead('D_Transfer_SearchByCode'), { CustomAttribute: 'Code', Exclusive: true })
        var Brand = !this.MEMRead('D_Transfer_Brand').ID || (this.MEMRead('D_Transfer_Brand').ID === item.BrandID)
        var Category = !this.MEMRead('D_Transfer_Category').ID || (this.MEMRead('D_Transfer_Category').ID === item.CategoryID)
        return (IsSearchByCode || IsSearch || IsBrand || IsCategory) && !ItemSelected && Search && SearchByCode && Brand && Category
      })
      return Filteredd
    },
    CashedTranslations () {
      return {
        Name: this.Translate('Name'),
        Brand: this.Translate('Brand'),
        Category: this.Translate('Category'),
        Available: this.Translate('Available'),
        'Not Available': this.Translate('Not Available'),
        Specs: this.Translate('Specs')
      }
    },
    DecodedAval () {
      return this.AvailableItems.map((item :any) => {
        item.DecodedLoc = (item.WarehouseName && item.WarehouseName !== '' ? item.WarehouseName : '') + `(${item.WarehouseCode})` + (item.SectionLevel1ID ? (' > ' + (item.Section1Name && item.Section1Name !== '' ? item.Section1Name : '') + `(${item.Section1Code})`) : '') + (item.SectionLevel2ID ? (' > ' + (item.Section2Name && item.Section2Name !== '' ? item.Section2Name : '') + `(${item.Section2Code})`) : '') + ' > ' + (item.SectionName && item.SectionName !== '' ? item.SectionName : '') + `(${item.SectionCode})`
        return item
      })
    },
    OtherBranches () {
      if (!this.Branches) return []
      return this.Branches.filter((branch :any) => {
        return branch.ID !== this.$store.state.BranchManagment.CurrentBranch?.ID
      })
    },
    NoUserSelectBranchConfig () {
      return !(this.$store.state.BranchManagment.CurrentBranch && this.$store.state.CookiesConfig[`Branch_${this.$store.state.BranchManagment.CurrentBranch?.ID}_IsUserSelect`] === 'true')
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadTransferSources()
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "504a2af0": (_ctx.Theme.Shade1),
  "44692127": (_ctx.Theme.Light1),
  "d9a52e38": (_ctx.Theme.BG5)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__