
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Res: { type: String },
    Default: { default: () => { return undefined }, type: Object as PropType<Calendar> }
  },
  data () {
    return {
      CalendarTypes: [{ Name: 'Yearly' }, { Name: 'Monthly' }, { Name: 'Weekly' }, { Name: 'Daily' }, { Name: 'Hourly' }],
      MonthsOfYear: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      DaysOfMonth: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
      DaysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      ErrorsVisibilty: false
    }
  },
  methods: {
  },
  computed: {
    CurrentValue () {
      var Type = this.MEMRead(`${this.Res}_Type`)
      var MonthsOfYear = ''
      var DaysOfMonth = ''
      var DaysOfWeek = ''
      var Seperator = ''
      if (Type === 'Yearly') {
        MonthsOfYear = ''
        DaysOfMonth = this.MEMRead(`${this.Res}_DaysOfYear`)
        this.MonthsOfYear.forEach((value, index :number) => {
          if (this.MEMRead(`${this.Res}_MonthsOfYear_${index}`)) {
            if (index > 0) MonthsOfYear += Seperator
            MonthsOfYear += value
            Seperator = ','
          }
        })
      }
      if (Type === 'Monthly') {
        DaysOfMonth = ''
        this.DaysOfMonth.forEach((value, index :number) => {
          if (this.MEMRead(`${this.Res}_DaysOfMonth_${index}`)) {
            if (index > 0) DaysOfMonth += Seperator
            DaysOfMonth += value
            Seperator = ','
          }
        })
      }
      if (Type === 'Weekly') {
        DaysOfWeek = ''
        this.DaysOfWeek.forEach((value, index :number) => {
          if (this.MEMRead(`${this.Res}_DaysOfWeek_${index}`)) {
            if (index > 0) DaysOfWeek += Seperator
            DaysOfWeek += value
            Seperator = ','
          }
        })
      }
      return {
        Type: Type,
        MonthsOfYear: MonthsOfYear,
        DaysOfMonth: DaysOfMonth,
        DaysOfWeek: DaysOfWeek
      }
    }
  },
  mounted () {
    if (this.Default) {
      // console.log(this.Default.MonthsOfYear)
      // Type
      this.MEMWrite(`${this.Res}_Type`, this.Default.Type)
      // //
      // DaysOfMonth
      var daysofmonth = [] as Array<string>
      if (this.Default.DaysOfMonth && this.Default.DaysOfMonth !== '') daysofmonth = this.Default.DaysOfMonth.split(',')
      this.DaysOfMonth.forEach((value, index) => {
        var Found = daysofmonth.find((Value) => {
          return Value === value
        })
        if (Found) {
          this.MEMWrite(`${this.Res}_DaysOfMonth_${index}`, true)
        }
      })
      // //
      // MonthsOfYear
      var monthsofyear = [] as Array<string>
      if (this.Default.MonthsOfYear && this.Default.MonthsOfYear !== '') monthsofyear = this.Default.MonthsOfYear.split(',')
      this.MonthsOfYear.forEach((value, index) => {
        var Found = monthsofyear.find((Value) => {
          return Value === value
        })
        if (Found) {
          this.MEMWrite(`${this.Res}_MonthsOfYear_${index}`, true)
        }
      })
      // //
      // DaysOfWeek
      var daysofweek = [] as Array<string>
      if (this.Default.DaysOfWeek && this.Default.DaysOfWeek !== '') daysofweek = this.Default.DaysOfWeek.split(',')
      this.DaysOfWeek.forEach((value, index) => {
        var Found = daysofweek.find((Value) => {
          return Value === value
        })
        if (Found) {
          this.MEMWrite(`${this.Res}_DaysOfWeek_${index}`, true)
        }
      })
      // //
    }
  },
  unmounted () {
    this.MEMClean(this.Res as string)
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
      }
    },
    CurrentValue: function (newVal, oldVal) { // watch it
      if (newVal !== oldVal) {
        this.MEMWrite(this.Res, this.CurrentValue)
      }
    }
  }
})
