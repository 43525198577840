
import { defineComponent } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  mounted () {},
  data () {
    return {
      ShowFilter: false,
      Search: ''
    }
  },
  computed: {
    Filters () :any { return [] },
    Tabs () {
      return [
        { Name: this.Translate('Accounts'), Link: 'Accounts' }
        // { Name: this.Translate('Invoices'), Link: 'Invoices' },
        // { Name: this.Translate('Transactions'), Link: 'Transactions' }
      ] as Array<CookiesTabs>
    },
    Name () {
      return this.Translate('Finance')
    }
  },
  methods: {

  }
})
