
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Filter: { default: () => {}, type: Object as PropType<FilterObject> },
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      CookiesData: [] as Array<Deduction>
    }
  },
  computed: {
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: 'New Deduction',
          this: this,
          Function: 'NewDeduction',
          Icon: 'Add',
          Visible: this.Access('HR_Deductions_Create')
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Employee',
            Path: ['Employee', 'Name'],
            Sort: ['Employee', 'Name']
          },
          {
            Type: 'Normal',
            Name: 'Reason',
            Path: ['Reason'],
            Sort: ['Reason']
          },
          {
            Type: 'DateTime',
            Name: 'Date',
            Path: ['AddedDate'],
            Sort: ['AddedDate']
          },
          {
            Type: 'Normal',
            Name: 'Salary Deduction',
            Path: ['SalaryDeduction'],
            Sort: ['SalaryDeduction'],
            Unit: this.PriceUnit
          },
          {
            Type: 'Normal',
            Name: 'Hours Deducted',
            Path: ['HourDeduction'],
            Sort: ['HourDeduction'],
            Unit: this.Translate('Hour')
          },
          {
            Type: 'Normal',
            Name: 'Days Deducted',
            Path: ['DayDeduction'],
            Sort: ['DayDeduction'],
            Unit: this.Translate('Day')
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Edit',
                Visible: this.Access('HR_Deductions_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditDeduction'
              },
              // {
              //   ID: 'Delete',
              //   Visible: this.Access('HR_Deductions_Remove'),
              //   ToolTip: this.Translate('Remove'),
              //   Emits: 'DeleteDeduction'
              // },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Deductions_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: 'DeleteDeduction',
                DynamicVisiblity: {
                  Operator: 'EQUAL',
                  FixedValue: null,
                  ValuePath: ['IsDeducted']
                }
              },
              {
                ID: 'Delete',
                Visible: this.Access('HR_Deductions_Remove'),
                ToolTip: this.Translate('Remove'),
                Emits: '',
                DynamicVisiblity: {
                  Operator: 'NOT EQUAL',
                  FixedValue: null,
                  ValuePath: ['IsDeducted']
                },
                Style: 'opacity: 0.3; cursor: normal;'
              }
            ]
          }
        ],
        TimeLine: {
          Path: 'WarningsTimeline'
        }
      }
    },
    FilteredData () {
      return this.CookiesFilter(this.CookiesData, this.Filter, this.Search)
    }
  },
  methods: {
    NewDeduction () {
      if (!this.Access('HR_Deductions_Create')) {
        return
      }
      this.OpenDialog('HR_Deduction_Dialog', this.Translate('New Deduction'), {}, {}) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('HR_Deductions').then(response => {
        this.CookiesData = response.map((value :Deduction) => {
          value.WarningsTimeline = value.Warnings?.map((warning) => {
            var Tooltip = `
            <span>Date: ${this.DateFormatter(warning.AddedDate)}</span><br/>
            <span>${this.Translate(warning.Reason)}</span>
            `
            return {
              Color: '#F4CE14',
              Icon: 'HasIssue',
              Tooltip: Tooltip
            } as TimeLine
          })
          var DeductionTooltip = `<span>Date: ${this.DateFormatter(value.AddedDate)}</span><br/>
            <span>${this.Translate('Deducted')}</span>`
          if (value.WarningsTimeline) {
            value.WarningsTimeline.push({
              Color: 'Red',
              Icon: 'Expenses',
              Tooltip: DeductionTooltip
            })
          }
          return value
        })
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Deductions'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditDeduction (Deduction :Deduction) {
      if (!this.Access('HR_Deductions_Read')) {
        return
      }
      this.OpenDialog('HR_Deduction_Dialog', 'Edit Deduction', { Data: Deduction, Editing: true }, {})
    },
    DeleteDeduction (Deduction :Deduction) {
      if (!this.Access('HR_Deductions_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Deductions', AddQuery: '/' + Deduction.ID, Text: 'Are you sure you want to delete <strong>Deduction</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewDeduction':
          this.OpenDialog('HR_Deduction_Dialog', this.Translate('New Deduction'), { Data: {} })
          break
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Buttons: function (newVal, oldVal) { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
