import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "Company"
}
const _hoisted_2 = { class: "CompanyInformation" }
const _hoisted_3 = {
  class: "D-Flex",
  style: {"justify-content":"space-between"}
}
const _hoisted_4 = { class: "TitleCompany" }
const _hoisted_5 = { style: {"margin":"0"} }
const _hoisted_6 = { class: "D-Flex" }
const _hoisted_7 = { class: "DescriptionCompany" }
const _hoisted_8 = { class: "Details" }
const _hoisted_9 = { class: "Data_card" }
const _hoisted_10 = { class: "Data_card" }
const _hoisted_11 = { class: "Data_card" }
const _hoisted_12 = { class: "Data_card" }
const _hoisted_13 = { class: "Data_card" }
const _hoisted_14 = { class: "Data_card" }
const _hoisted_15 = { class: "Data_card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesIcon = _resolveComponent("CookiesIcon")!

  return (_ctx.Company)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "Image",
          style: _normalizeStyle('background-image: url(' + _ctx.DefaultImageHandler(_ctx.Company.LogoURL, _ctx.$store.state.CookiesConfig.DefaultImage) + ');')
        }, null, 4),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.Company.Name), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.Company.LegalName), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "FavIcon",
                style: _normalizeStyle('background-image: url(' + _ctx.DefaultImageHandler(_ctx.Company.FavIconURL, _ctx.$store.state.CookiesConfig.DefaultImage) + ');')
              }, null, 4),
              _createVNode(_component_CookiesIcon, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.EditCompany(_ctx.Company))),
                ID: ('Edit' as IconsIDs),
                class: "SQR2em HoverUp",
                ToolTip: _ctx.Translate('Edit')
              }, null, 8, ["ToolTip"]),
              _createVNode(_component_CookiesIcon, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.DeleteCompany(_ctx.Company))),
                ID: ('Delete' as IconsIDs),
                class: "SQR2em HoverUp",
                ToolTip: _ctx.Translate('Remove')
              }, null, 8, ["ToolTip"])
            ])
          ]),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.Company.Description), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.Translate('City')) + " : " + _toDisplayString(_ctx.Company.City), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.Translate('CompanyNO')) + " : " + _toDisplayString(_ctx.Company.CompanyNO), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.Translate('Tax Ratio')) + " : " + _toDisplayString(_ctx.Company.TaxRatio) + " %", 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.Translate('Province')) + " : " + _toDisplayString(_ctx.Company.Province), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.Translate('Founding Date')) + " : " + _toDisplayString(_ctx.DateFormatter(_ctx.Company.FoundingDate)), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.Translate('Currency')) + " : " + _toDisplayString(_ctx.Company.Currency + _ctx.Translate(_ctx.PriceUnit)), 1),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.Translate('Street')) + " : " + _toDisplayString(_ctx.Company.Street), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}