
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      timer: 0, // Initial value for the timer in seconds
      intervalId: '' as any, // Variable to store the interval ID
      OriginalData: {} as any,
      Saving: false,
      Loading: [] as Array<string>,
      ErrorsVisibilty: false,
      DialogMapper: [] as Array<DialogMapperObject>,
      EmployeeData: {} as Employee,
      DeviceOTP: '' as any,
      OTPExpired: false
    }
  },
  methods: {
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'HR_Departments',
        OriginalData: this.OriginalData,
        PatchAPI: 'HR_Departments',
        Editing: this.DialogData.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    GetOTP () {
      this.Post('POST', 'HR_Devices_OTP', {
        EmployeeID: this.EmployeeData.ID
      }).then((response: any) => {
        this.DeviceOTP = response.OTP
      })
      this.OTPExpired = false
      this.timer = 60
      this.startTimer()
    },
    ReadEmployee () {
      this.Get('HR_Employees', `?ID=${this.EmployeeData.ID}`).then(response => {
        this.EmployeeData = response
        if (this.EmployeeData.Devices) {
          this.stopTimer()
          this.OTPExpired = true
          this.timer = 0
        }
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Employee'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DeleteDevice (Device :HRDevices) {
      if (!this.Access('HR_Devices_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'HR_Devices', AddQuery: '/' + Device.ID, Text: 'Are you sure you want to delete <strong>' + Device.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete'), AfterDialogFunction: this.ReadEmployee } as GeneralAcceptDialogData, {})
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    },
    startTimer () {
      // Set up an interval to decrement the timer every second
      this.timer = 60
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--
          if (this.timer % 5 === 0) {
            this.ReadEmployee()
          }
        } else {
          // Stop the timer when it reaches 0
          this.OTPExpired = true
          this.stopTimer()
        }
      }, 1000)
    },
    stopTimer () {
      // Clear the interval when the timer is stopped
      clearInterval(this.intervalId)
    }
  },
  mounted () {
    this.EmployeeData = this.DialogData.Data
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
      this.Console(this.OriginalData)
    }
  },
  beforeUnmount () {
    this.stopTimer()
  }
})
