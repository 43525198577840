import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-051336c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}
const _hoisted_2 = {
  key: 0,
  class: "DialogSection"
}
const _hoisted_3 = ["ToolTip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CookiesFunctionalTable, {
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, {
      default: _withCtx(({ Field, item }) => [
        (_ctx.DeepFetcher(item, Field.Path))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DeepFetcher(item, Field.Path), (attendance) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["Attendance", attendance.Type]),
                  key: attendance,
                  ToolTip: _ctx.TooltipDesign(attendance)
                }, null, 10, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["Loading", "CookiesData", "TableSettings"])
  ], 64))
}