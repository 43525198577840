
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Search: { type: String, default: '' },
    ParentThis: { type: Object as PropType<any> }
  },
  mounted () {
    this.ReadCookiesData()
    this.ChangeTableButtons(this.ParentThis, this.Buttons)
    this.ChangeFilter(this.ParentThis, this.Filter)
  },
  data () {
    return {
      Loading: [] as Array<string>,
      Saving: false,
      CookiesData: [] as Array<Catalog>
    }
  },
  computed: {
    CatalogsLimit () {
      if (this.$store.state.CookiesConfig.Catalog_Limit) return parseInt(this.$store.state.CookiesConfig.Catalog_Limit)
      return 15
    },
    Filter () :FilterObject | undefined {
      return undefined
    },
    Buttons () :Array<FunctionalTableButton> {
      return [
        {
          Name: this.Translate('New Catalog'),
          this: this,
          Function: 'NewCatalog',
          Icon: 'Add',
          Visible: this.Access('Catalogue_Create')
        },
        {
          Name: this.Translate('View Catalog'),
          this: this,
          Function: 'ViewCatalogs',
          Icon: 'OpenLink',
          IconColor: { ColorA: 'rgba(0,0,0,0.5)' },
          Visible: this.Access('Catalogue_Read')
        },
        {
          Name: this.Translate('Send'),
          this: this,
          Function: 'SendWhats',
          Icon: 'Whatsapp',
          Visible: this.$store.state.CookiesConfig.WhatsappStatus === 'true'
        }
      ]
    },
    TableSettings () :FunctionalTable {
      return {
        Fields: [
          {
            Type: 'Index'
          },
          {
            Type: 'Normal',
            Name: 'Name',
            Path: ['Name'],
            Bold: true
          },
          {
            Type: 'Normal',
            Name: 'Description',
            Path: ['Description']
          },
          {
            Type: 'Normal',
            Name: 'Items',
            Path: ['ItemsCount']
          },
          {
            Type: 'Normal',
            Name: 'Sort',
            Path: ['Configs', 'Sort']
          },
          {
            Type: 'StatusBox',
            Path: 'visibility',
            Name: this.Translate('visibility'),
            StatusClassColors: { Visible: 'BGGreen', Hidden: 'BGRed' },
            Emits: 'ChangeHiddenState',
            Class: 'HoverGlow',
            Bold: true
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Arrow',
                DynamicVisiblity: {
                  Operator: 'LARGER',
                  FixedValue: 0,
                  ValuePath: ['Configs', 'Sort']
                },
                Style: 'transform: rotate(-90deg);',
                ToolTip: this.Translate('Move Up'),
                Emits: 'MoveUp'
              },
              {
                ID: 'Arrow',
                DynamicVisiblity: {
                  Operator: 'SMALLER',
                  FixedValue: this.FilteredData.length - 1,
                  ValuePath: ['Configs', 'Sort']
                },
                ToolTip: this.Translate('Move Down'),
                Style: 'transform: rotate(90deg);',
                Emits: 'MoveDown'
              }
            ]
          },
          {
            Type: 'ActionIcons',
            ActionIcons: [
              {
                ID: 'Whatsapp',
                Visible: this.$store.state.CookiesConfig.WhatsappStatus === 'true',
                ToolTip: this.Translate('Send on WhatsApp'),
                Emits: 'SendWhatsapp'
              },
              {
                ID: 'Link',
                Visible: this.Access('Catalogue_Read'),
                ToolTip: this.Translate('Copy Link'),
                Emits: 'CopyLink'
              },
              {
                ID: 'OpenLink',
                Visible: this.Access('Catalogue_Read'),
                ToolTip: this.Translate('Open Catalog'),
                Link: 'CatalogLink'
              },
              {
                ID: 'Edited',
                Visible: this.Access('Catalogue_Edit'),
                ToolTip: this.Translate('Edit'),
                Emits: 'EditCatalog'
              },
              {
                ID: 'Delete',
                Emits: 'DeleteCatalog',
                Visible: this.Access('Catalogue_Remove'),
                ToolTip: this.Translate('Delete')
              }
            ]
          }
        ]
      }
    },
    FilteredData () {
      var CurruntCatalogs = this.CookiesData as any
      CurruntCatalogs.map((catalog :any, index :number) => {
        catalog.ItemsCount = this.DeepFetcher(catalog, ['Items', 'length'], 'N/A')
        catalog.CatalogLink = this.$store.state.CookiesConfig.Catalog_MainURL + '/' + catalog.Sequence
        catalog.visibility = catalog.IsHidden ? 'Hidden' : 'Visible'
        catalog.index = index
        catalog.Configs.Sort = parseInt(catalog.Configs.Sort)
        return catalog
      })
      return this.CookiesFilter(this.GeneralSort(CurruntCatalogs, ['Configs', 'Sort'], 1), this.Filter, this.Search)
    }
  },
  methods: {
    ReadCookiesData () {
      this.Loading.push('Loading') // Add Loading flag
      this.Get('Catalogs').then(response => {
        this.Saving = false
        this.CookiesData = response
        this.Loading.pop() // Remove Loading flag
      }, error => {
        this.Saving = false
        this.Loading.pop() // Remove Loading flag
        error.CookiesError = 'Error in reading Catalogs'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditCatalog (Catalog :Catalog) {
      if (!this.Access('Catalogue_Edit')) {
        return
      }
      this.OpenDialog('Catalog_EditDialog', 'Edit Catalog(' + Catalog.Name + ')', { Data: Catalog, Editing: true }, {})
    },
    CopyLink (Catalog :Catalog) {
      const elem = document.createElement('textarea')
      elem.value = Catalog.CatalogLink ? Catalog.CatalogLink : ''
      document.body.appendChild(elem)
      elem.select()
      document.execCommand('copy')
      document.body.removeChild(elem)
      this.PushNotification('Link Copied')
    },
    OpenCatalog (Catalog :Catalog) {
      this.$router.push('/Catalogs/ViewCatalog/' + Catalog.ID)
    },
    DeleteCatalog (Catalog :Catalog) {
      if (!this.Access('Catalogue_Remove')) {
        return
      }
      this.OpenDialog('General_Accept_Dialog', 'Delete', { PostMethod: 'DELETE', PostAPI: 'Catalogs', AddQuery: '/' + Catalog.ID, Text: 'Are you sure you want to delete <strong>' + Catalog.Name + '</strong>', ButtonClass: 'BGRed', ButtonText: this.Translate('Delete') } as GeneralAcceptDialogData, {})
    },
    ButtonsFunctions (button :string) {
      switch (button) {
        case 'NewCatalog':
          if (this.CookiesData.length >= this.CatalogsLimit) {
            this.OpenDialog('General_Accept_Dialog', this.Translate('Limits Reached!'), { ButtonClass: 'D-None', ButtonStyle: 'display: none;', PostAPI: '', Icon: 'Close', Text: this.Translate('Sorry! you have reached yur catalogs limits') })
          } else {
            this.OpenDialog('Catalog_Dialog', this.Translate('New Catalog'), { Sort: this.FilteredData.length })
          }
          break
        case 'ViewCatalogs':
          window.open(this.$store.state.CookiesConfig?.Catalog_MainURL, 'blank')
          break
        case 'SendWhats':
          if (this.$store.state.CookiesConfig.WhatsappStatus === 'true') {
            var message = {
              messaging_product: 'whatsapp',
              to: '',
              type: 'template',
              template: {
                name: 'viewsaedyecatalogs',
                language: {
                  code: 'en'
                },
                components: [
                  {
                    type: 'header',
                    parameters: [
                      {
                        type: 'image',
                        image: {
                          link: this.$store.state.CookiesConfig.HeroImage
                        }
                      }
                    ]
                  },
                  {
                    type: 'body',
                    parameters: [
                      {
                        type: 'text',
                        text: this.$store.state.CookiesConfig.WhatsappContact
                      }
                    ]
                  }
                ]
              }
            }
            this.OpenDialog('Integration_WhatsappMessage_Dialog', 'Send Message on WhatsApp', { Data: message, Title: 'All Catalogs' })
          } else {
            this.PushNotification(this.Translate('WhatsApp Integration not Verified!!'))
          }
          break
      }
    },
    SendWhatsapp (catalog :Catalog) {
      if (this.$store.state.CookiesConfig.WhatsappStatus === 'true') {
        var CatalogName = catalog.Name.trim().replace(/(\r\n|\n|\r)/gm, '').replace(/(\r\n|\n|\r)/, '').replaceAll('\r', '').replaceAll('\n', '').replaceAll('<br>', '').replaceAll('<br/>', '').trim()
        CatalogName = CatalogName.replace(/(\r\n|\n|\r)/gm, '<1br />')
        var re1 = /<1br \/><1br \/>/gi
        // var re1a = /<1br \/><1br \/><1br \/>/gi
        CatalogName = CatalogName.replace(re1, ' ')
        var re2 = /\\<1br \/>/gi
        CatalogName = CatalogName.replace(re2, ' ')
        var re3 = /\s+/g
        CatalogName = CatalogName.replace(re3, ' ')
        var re4 = /<2br \/>/gi
        CatalogName = CatalogName.replace(re4, '\n\n')
        var message = {
          messaging_product: 'whatsapp',
          to: '',
          type: 'template',
          template: {
            name: 'dynamic_catalog',
            language: {
              code: 'en'
            },
            components: [
              {
                type: 'header',
                parameters: [
                  {
                    type: 'image',
                    image: {
                      link: catalog.ImgURL
                    }
                  }
                ]
              },
              {
                type: 'body',
                parameters: [
                  {
                    type: 'text',
                    text: CatalogName
                  },
                  {
                    type: 'text',
                    text: this.$store.state.CookiesConfig.WhatsappContact
                  }
                ]
              },
              {
                type: 'button',
                sub_type: 'URL',
                index: '0',
                parameters: [
                  {
                    type: 'payload',
                    payload: catalog.Sequence
                  }
                ]
              }
            ]
          }
        }
        this.OpenDialog('Integration_WhatsappMessage_Dialog', 'Send Message on WhatsApp', { Data: message, Title: CatalogName })
      } else {
        this.PushNotification(this.Translate('WhatsApp Integration not Verified!!'))
      }
    },
    HideUnhideCatalog (catalog :any) {
      var Patch = {
        IsHidden: !catalog.IsHidden,
        Name: catalog?.Name,
        ImgURL: catalog?.ImgURL,
        Configs: catalog?.Configs
      }
      this.Post('PATCH', 'Catalogs', Patch, '/' + catalog.ID).then(response => {
        this.ReadCookiesData()
      }, error => {
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Move (Catalog : any, direction : 1|-1) {
      this.Saving = true
      this.GlobalSave()
      var Cat1 = this.FilteredData[Catalog.Configs.Sort]
      var Cat2 = this.FilteredData[Catalog.Configs.Sort + direction]
      this.Post('PATCH', 'Catalogs', {
        Name: Cat1?.Name,
        ImgURL: Cat1?.ImgURL,
        IsHidden: Cat1?.IsHidden,
        Configs: {
          Sort: Catalog.Configs.Sort + direction
        }
      },
      '/' + Cat1?.ID
      ).then((value) => {
        this.Post('PATCH', 'Catalogs', {
          Name: Cat2?.Name,
          ImgURL: Cat2?.ImgURL,
          IsHidden: Cat2?.IsHidden,
          Configs: {
            Sort: Catalog.Configs.Sort
          }
        },
        '/' + Cat2?.ID
        ).then((value) => {
          this.ReadCookiesData()
          this.GlobalSaveDone()
        }, error => {
          this.Post('PATCH', 'Catalogs', {
            Name: Cat1?.Name,
            ImgURL: Cat1?.ImgURL,
            IsHidden: Cat1?.IsHidden,
            Configs: {
              ...Cat1?.Configs,
              Sort: Catalog.Configs.Sort
            }
          },
          '/' + Cat1?.ID
          ).then((value) => {
            this.ReadCookiesData()
            this.GlobalSaveDone(false)
            this.Saving = false
          }, error => {
            this.Saving = false
            this.GlobalSaveDone(false)
            error.CookiesError = 'Error in Editing'
            this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
          this.Saving = false
          this.GlobalSaveDone(false)
          error.CookiesError = 'Error in Editing'
          this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }, error => {
        this.Saving = false
        this.GlobalSaveDone(false)
        error.CookiesError = 'Error in Editing'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    MoveUp (Catalog : any) {
      this.Move(Catalog, -1)
    },
    MoveDown (Catalog : any) {
      this.Move(Catalog, 1)
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCookiesData()
      }
    },
    Filter: function () { // watch it
      this.ChangeFilter(this.ParentThis, this.Filter)
    },
    Buttons: function () { // watch it
      this.ChangeTableButtons(this.ParentThis, this.Buttons)
    }
  }
})
