
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  name: 'Cookies Image Viewer',
  created () {},
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  }
})
