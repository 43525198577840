
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

export default defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    DialogIndex: Number,
    DialogData: { default: () => { return {} }, type: Object as PropType<DialogData> }
  },
  data () {
    return {
      TableSaving: [] as Array<'Saving'>,
      Saved: undefined as boolean|undefined,
      Loading: [] as Array<'Loading'>,
      CookiesData: {} as any,
      Categories: [] as any,
      CookiesContact: [] as any,
      ErrorsVisibilty: false,
      OriginalData: {} as any,
      Saving: false
    }
  },
  computed: {
    TableSettings () {
      var Table = {
        TableName: this.Translate('Categories') + this.DialogData.Type ? ' ' + this.DialogData.Type : '',
        CookiesID: 'D_Company_ContactInfo',
        ResID: 'TempID',
        Fields: [
          {
            Type: 'index',
            Value: '',
            Name: 'index',
            NoHeader: true,
            Style: 'width: 3em'
          },
          {
            Name: this.Translate('Type'),
            ToolTip: 'Phone Number, Email, Fax,........',
            Type: 'normal',
            Value: 'Name',
            Sort: 'Name',
            Editable: true,
            Bold: true,
            Res_ID: 'TempID'
          },
          {
            Name: this.Translate('Value'),
            ToolTip: '079XXXXXXX, Test@Cookies.Bis.com,........',
            Editable: true,
            Type: 'normal',
            Value: 'Description',
            Sort: 'Description',
            Res_ID: 'TempID'
          }
        ],
        Footer: false,
        Header: true,
        HideTableName: true,
        TableSelectDefaults: false,
        DefaultSort: {
          Direction: 1,
          Path: 'AddedDate'
        }
      } as CookiesTable
      Table.Fields.push({
        Name: 'Delete',
        Value: '',
        Type: 'delete',
        NoHeader: true
      })
      return Table
    },
    DialogMapper () {
      var DM = [
        { Path: 'Name', Res: 'D_Company_Name' },
        { Path: 'LegalName', Res: 'D_Company_LegalName' },
        { Path: 'Description', Res: 'D_Company_Description' },
        { Path: 'LogoURL', Res: 'D_Company_Logo', IsImage: true },
        { Path: 'FavIconURL', Res: 'D_Company_FavIcon', IsImage: true },
        { Path: 'CompanyNO', Res: 'D_Company_CompanyNO' },
        { Path: 'TaxRatio', Res: 'D_Company_TaxRatio' },
        { Path: 'Currency', Res: 'D_Company_Currency' },
        { Path: 'Province', Res: 'D_Company_Province' },
        { Path: 'City', Res: 'D_Company_City' },
        { Path: 'Street', Res: 'D_Company_Street' },
        { Path: 'FoundingDate', Res: 'D_Company_FoundingDate' }
      ]
      if (!this.DialogData.Editing) DM.push({ Path: 'ContactInfo', Res: 'D_Company_ContactInfo' })
      return DM as Array<DialogMapperObject>
    }
  },
  methods: {
    ReadDialogData () {
      this.ReadContacts()
    },
    FieldEdited (item :any) {
      console.log(item)
      if (!this.DialogData.Editing) return
      this.Saved = undefined
      this.TableSaving.push('Saving')
      var Original = this.CookiesContact.find((cat :Company) => {
        return cat.ID === item.ID
      })
      var Patch = this.CookiesPatcher(Original, item)
      this.Post('PATCH', 'Companies', Patch, '/ID=' + item.ID).then((response) => {
        this.TableSaving.shift()
        this.Saved = true
        this.ReadContacts()
      }, error => {
        this.TableSaving.shift() // Remove Loading flag
        this.Saved = false
        console.log(error)
      })
    },
    RemoveContact (row:any) {
      console.log('remove')
      console.log(row)
      console.log(this.CookiesContact[row.OriginalIndex])
      if (this.DialogData.Editing) {
        this.Post('DELETE', 'Companies', {}, '/ID=' + row.ID).then(() => {
          this.ReadContacts()
        })
      } else {
        this.CookiesContact.splice(row.OriginalIndex, 1)
      }
    },
    ReadContacts () {
      if (!this.DialogData.Editing) return
      this.Loading.push('Loading')
      this.Get('Categories', '?Type=CompanyContactInfo&RefID=' + this.DialogData.Data.ID).then(response => {
        this.CookiesContact = response.map((element :any) => {
          element.TempID = element.ID
          return element
        })
        this.Loading.shift() // Remove Loading flag
      }, error => {
        this.Loading.shift() // Remove Loading flag
        error.CookiesError = 'Error in reading Companies Contact'
        this.OpenDialog('Error_Dialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    AddContact () {
      if (this.DialogData.Editing) {
        this.Post('POST', 'Categories', {
          Type: 'CompanyContactInfo',
          Description: '',
          ImgUrl: '',
          RefID: this.DialogData.Data.ID
        }).then(() => {
          this.ReadDialogData()
        })
      } else {
        this.CookiesContact.push({
          Name: '',
          Description: '',
          TempID: 'TempID' + new Date().getTime()
        })
      }
    },
    SaveData () {
      this.SaveDialogData({
        DialogMapper: this.DialogMapper,
        Parentthis: this,
        PostAPI: 'Companies',
        OriginalData: this.OriginalData,
        PatchAPI: 'Companies',
        Editing: this.DialogData.Editing,
        PatchQuery: '/' + this.DialogData?.Data?.ID
      })
    },
    DataSaved () {
      this.Saving = false
      this.$router.go(-1)
    }
  },
  unmounted () {
    this.MEMClean('D_Company')
  },
  created () {
    if (this.DialogData.Data) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data) as Company
    }
  },
  mounted () {
    const Dialog = document.getElementById('CookiesDialog_' + this.DialogIndex)
    if (Dialog) {
      window.scrollTo(0, Dialog.offsetTop - 60)
    }
    if (this.DialogData.Editing) {
      this.OriginalData = this.CookiesDialogInitiator(this.DialogMapper, this.DialogData.Data)
      this.Console(this.OriginalData)
    }
  }
})
