
import { defineComponent, PropType } from 'vue'
import CookiesCoreMixinVue from '@/modules/CookiesCoreMixin.vue'

const __default__ = defineComponent({
  mixins: [CookiesCoreMixinVue],
  props: {
    Width: { default: '', type: String },
    Default: { default: () => { return undefined }, type: Object as PropType<any> },
    Height: { default: '', type: String },
    Background: { default: '', type: String },
    Align: { default: 'center', type: String },
    Options: { default: () => { return [] }, type: Array as PropType<Array<any>> },
    Selector: { default: 'Name' },
    Type: { default: 'String', type: String },
    Res: { default: 'String', type: String },
    Radio: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean },
    Icons: { default: [] },
    CookiesIcons: { default: [] },
    Name: { default: '' },
    HoverDark: { default: false },
    MaxHeight: { default: '16em', type: String },
    IndexSelect: { default: 0, type: Number },
    CanBeNone: { default: false, type: Boolean }
  },
  data () {
    return {
      Opened: false,
      SelectedImage: '',
      SelectedIcon: '',
      CurrentIndex: 0
    }
  },
  mounted () {
    this.MountedFunction()
  },
  methods: {
    MountedFunction () {
      var selectedIndex = 0
      if (this.$store.state.CookiesMemory[this.Res]) {
        if (this.Type === 'String') {
          var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
          if (Found > -1) {
            selectedIndex = Found
          }
        } else {
          var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
          if (Found2 > -1) {
            selectedIndex = Found2
          }
        }
      } else if (this.Default) {
        if (this.Type === 'String') {
          var Found3 = this.ALLOptions.findIndex((value) => { return value === this.Default })
          if (Found3 > -1) {
            selectedIndex = Found3
          }
        } else {
          var Found4 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.Default })
          if (Found4 > -1) {
            selectedIndex = Found4
          }
        }
      }
      this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
      this.SelectedImage = this.ALLIcons[selectedIndex]
      this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
    },
    OptionSelected (option: any, Index: number, event: any) {
      var selected = option
      this.CurrentIndex = Index
      this.$store.state.CookiesMemory[this.Res] = selected
      this.SelectedImage = this.ALLIcons[Index]
      this.SelectedIcon = this.ALLCookiesIcons[Index]
      this.$emit('slection_changed', this.$store.state.CookiesMemory[this.Res])
      this.$emit('selected', this.$store.state.CookiesMemory[this.Res])
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        if (this.CanBeNone) {
          if (this.Type === 'Object') {
            var NoneOption = {}
            this.DeepChanger(NoneOption, this.SelectorFix, this.Translate('None'))
            return [NoneOption].concat(this.Options)
          }
          return [this.Translate('None')].concat(this.Options)
        }
        return this.Options
      } else if (this.Type === 'Object') {
        // var newObj = [{}].concat(this.Options)
        var newObj = {}
        var NewArray = []
        if (this.CanBeNone) {
          var newOption = {}
          this.DeepChanger(newOption, this.SelectorFix, this.Translate('None'))
          NewArray.push(newOption)
        }
        this.DeepChanger(newObj, this.SelectorFix, this.LanguageSelector('All', 'الكل'))
        NewArray.push(newObj)
        NewArray = NewArray.concat(this.Options)
        // newObj[0][this.Selector] = 'All'
        return NewArray
      }
      if (this.CanBeNone) {
        return [this.Translate('None'), this.Translate('All')].concat(this.Options)
      }
      return [this.Translate('All')].concat(this.Options)
    },
    ALLIcons () {
      if (this.Radio) {
        return this.Icons
      }
      return [''].concat(this.Icons)
    },
    ALLCookiesIcons () :Array<IconsIDs> {
      if (this.Radio) {
        return this.CookiesIcons
      }
      return [''].concat(this.CookiesIcons) as Array<IconsIDs>
    },
    StyledOut () {
      var BGG
      if (this.Background !== '') {
        BGG = this.Background
      } else {
        BGG = this.Theme.BG6
      }
      return {
        width: this.Width,
        height: this.Height,
        background: BGG
      }
    },
    Selected () {
      var x = {}
      x = this.$store.state.CookiesMemory[this.Res]
      return x
    },
    TextAlign () {
      return `text-align: ${this.Align.toLowerCase()}; justify-content: ${this.Align.toLowerCase()};`
    },
    SelectedName () {
      if (this.Type === 'Object') {
        try {
          return this.DeepFetcher(this.Selected, this.SelectorFix)
        } catch (error) {
          return ''
        }
      }
      return this.Selected
    },
    SelectorFix () {
      if (typeof this.Selector === 'string') {
        return [this.Selector]
      }
      return this.Selector
    }
  },
  watch: {
    // Selected: function (newVal, oldVal) { // watch it
    //   this.SelectedImage = this.ALLIcons[this.ALLOptions.indexOf(newVal)]
    //   this.SelectedIcon = this.ALLCookiesIcons[this.ALLOptions.indexOf(newVal)]
    //   if (this.Type === 'Object') {
    //     try {
    //       var Path = this.PathFixer(this.Selector)
    //       // if (typeof this.Selector === 'string') {
    //       //   Path = [this.Selector]
    //       // }
    //       this.SelectedIcon = this.ALLCookiesIcons[this.IndexFinder(this.ALLOptions, Path, newVal[this.Selector])]
    //     } catch (error) {}
    //   }
    // },
    ALLOptions: function (newVal, oldVal) { // watch it
      try {
        // this.MountedFunction()
        // if (this.Selected === undefined || !(this.Options.indexOf(this.Selected))) {
        //   var selectedIndex = 0
        //   // if (this.$store.state.CookiesMemory[this.Res]) {
        //   //   if (this.Type === 'String') {
        //   //     var Found = this.ALLOptions.findIndex((value) => { return value === this.$store.state.CookiesMemory[this.Res] })
        //   //     if (Found > -1) {
        //   //       selectedIndex = Found
        //   //     }
        //   //   } else {
        //   //     var Found2 = this.ALLOptions.findIndex((value) => { return this.DeepFetcher(value, this.SelectorFix) === this.$store.state.CookiesMemory[this.Res] })
        //   //     if (Found2 > -1) {
        //   //       selectedIndex = Found2
        //   //     }
        //   //   }
        //   // }
        //   // this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[selectedIndex]
        //   // this.SelectedImage = this.ALLIcons[selectedIndex]
        //   // this.SelectedIcon = this.ALLCookiesIcons[selectedIndex]
        //   // this.$emit('slection_changed', this.$store.state.CookiesMemory[this.Res])
        //   // this.$emit('selected', this.$store.state.CookiesMemory[this.Res])
        // }
      } catch (error) {
        console.log(error)
      }
    },
    // IndexSelect: function (newVal, oldVal) { // watch it
    //   this.$store.state.CookiesMemory[this.Res] = this.ALLOptions[this.IndexSelect]
    // },
    Default: function (newVal, oldVal) { // watch it
      this.MEMClean(this.Res)
      this.MountedFunction()
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0042d699": (_ctx.Theme.BackBlur),
  "4a25f87a": (_ctx.Theme.color2),
  "521f3dee": (_ctx.Theme.BG6Fixed),
  "d7e4fdbc": (_ctx.Theme.BG2),
  "d7e4fdba": (_ctx.Theme.BG3)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__