import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "BigLoading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CookiesLoading = _resolveComponent("CookiesLoading")!
  const _component_CookiesFunctionalTable = _resolveComponent("CookiesFunctionalTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Loading.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createVNode(_component_CookiesLoading)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_CookiesFunctionalTable, {
      class: _normalizeClass(_ctx.Saving ? 'Disabled' : ''),
      onMoveDown: _ctx.MoveDown,
      onMoveUp: _ctx.MoveUp,
      onChangeHiddenState: _ctx.HideUnhideCatalog,
      onSendWhatsapp: _ctx.SendWhatsapp,
      onOpenCatalog: _ctx.OpenCatalog,
      onCopyLink: _ctx.CopyLink,
      onEditCatalog: _ctx.EditCatalog,
      onDeleteCatalog: _ctx.DeleteCatalog,
      Loading: _ctx.Loading,
      CookiesData: _ctx.FilteredData,
      TableSettings: _ctx.TableSettings
    }, null, 8, ["class", "onMoveDown", "onMoveUp", "onChangeHiddenState", "onSendWhatsapp", "onOpenCatalog", "onCopyLink", "onEditCatalog", "onDeleteCatalog", "Loading", "CookiesData", "TableSettings"])
  ], 64))
}